<template>
  <component
    :is="tag"
    class="inline-flex items-center cursor-pointer"
    :class="{
      'px-1.5 py-1 rounded-md font-extrabold hover:bg-brand-blue-50 focus:ring-2 focus:ring-brand-blue-100': link,
      'text-brand-blue': link && tag === tags.BUTTON,
      'justify-center px-5 py-2 border border-transparent text-sm font-extrabold rounded-full text-black hover:text-black focus:outline-none': !link,
      'bg-primary-500 hover:bg-primary-600 shadow-lg shadow-primary-700/25 hover:shadow-lg focus:ring-2 focus:ring-offset-2 focus:ring-primary-600': !link && variant === variants.PRIMARY,
      'bg-neutral-200 hover:bg-neutral-300 focus:ring-2 focus:ring-offset-2 focus:ring-neutral-300': !link && variant === variants.SECONDARY,
      'bg-neutral-300 hover:bg-neutral-400 focus:ring-2 focus:ring-offset-2 focus:ring-neutral-400': !link && variant === variants.SECONDARY_DARK
    }"
    :href="tag === tags.A && href"
    @click="tag === tags.BUTTON && go(href)"
  >
    <font-awesome-icon
      v-if="icon && iconPlacement === iconPlacements.BEFORE"
      class="mr-2 first:last:mx-0"
      :class="{ '-ml-1': !link }"
      :icon="['fas', icon]"
    />
    <span v-if="$slots.default"><slot /></span>
    <font-awesome-icon
      v-if="icon && iconPlacement === iconPlacements.AFTER"
      class="ml-2 first:last:mx-0"
      :class="{ '-mr-1': !link }"
      :icon="['fas', icon]"
    />
  </component>
</template>

<script>
const iconPlacements = {
  BEFORE: 'before',
  AFTER: 'after'
}

const variants = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SECONDARY_DARK: 'secondary-dark'
}

const tags = {
  A: 'a',
  BUTTON: 'button'
}

export default {
  props: {
    href: String,
    icon: {
      type: [String, Boolean],
      default: 'angle-right'
    },
    iconPlacement: {
      type: String,
      default: iconPlacements.AFTER
    },
    variant: {
      type: String,
      default: variants.PRIMARY,
      validator: (value) => Object.values(variants).includes(value)
    },
    link: Boolean,
    tag: {
      type: String,
      default: tags.A,
      validator: (value) => Object.values(tags).includes(value)
    }
  },
  data() {
    return {
      iconPlacements,
      variants,
      tags
    }
  },
  methods: {
    go(href) {
      if (href) {
        window.location.href = href
      }
    }
  }
}
</script>
