<template>
  <nav>
    <template v-if="links.length">
      <a v-for="(link, index) in links" :key="index" class="group flex items-center px-2 py-2 text-base md:text-sm font-medium rounded-md" :class="currentPageUnder(link.href) ? 'bg-neutral-700 text-white hover:text-white' : 'text-neutral-400 hover:bg-neutral-800 hover:text-white'" :href="link.href" :target="(link.icon == 'browser') ? '_blank' : '_self'">
        <font-awesome-icon v-if="link.icon" :icon="['fas', link.icon]" class="mr-14px flex-shrink-0 h-6 w-6" :class="currentPageUnder(link.href) ? 'text-primary' : 'text-neutral-400 group-hover:text-primary'" />
        {{ link.name }}
      </a>
    </template>
  </nav>
</template>

<script>
export default {
  props: {
    links: Array
  },
  methods: {
    currentPageUnder(path) {
      return window.location.pathname.startsWith(path)
    }
  }
}
</script>
