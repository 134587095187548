<template>
  <div>
    <h3>My Plan</h3>
    <alert class="my-4" v-if="activeUntil.length > 0" type="info" :dismissible="false">Your subscription will not renew but will remain active until {{activeUntil}}</alert>
    <div class="mt-4 grid isolate max-w-sm grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:max-w-none xl:grid-cols-3">
      <plan-option name="The Turf"
                   description="Great for just starting out."
                   :is-selected="tier == '0'"
                   init-tier="0"
                   init-period="monthly"
                   price="0"
                   :features="['20% pay-per-view revenue share','$1 / subscriber / mo','Web only','100 viewer hours']"></plan-option>
      <plan-option name="7th Street"
                   description="Perfect for established artists."
                   :is-selected="tier == '1'"
                   init-tier="1"
                   init-period="monthly"
                   price="99"
                   :features="['10% pay-per-view revenue share','$1 / subscriber / mo','Web + Apps','250 viewer hours']"></plan-option>
      <plan-option name="Main Room"
                   description="Built for groups and labels."
                   :is-selected="tier == '2'"
                   init-tier="2"
                   init-period="monthly"
                   price="299"
                   :features="['10% pay-per-view revenue share','$1 / subscriber / mo','Web + Apps','1,000 viewer hours','5% merch revenue share']"></plan-option>
      <plan-option name="The Line"
                   description="When you're ready for the big show."
                   :is-selected="tier == '3'"
                   init-tier="3"
                   init-period="monthly"
                   price="799"
                   :features="['10% pay-per-view revenue share','$1 / subscriber / mo','Web + Apps','3,000 viewer hours','5% merch revenue share']"></plan-option>
      <plan-option name="The Palace"
                   description="Designed for organizations."
                   :is-selected="false"
                   init-tier="4"
                   price="contact"
                   :features="['10% pay-per-view revenue share','$1 / subscriber / mo','Web + Apps','5,000 viewer hours','5% merch revenue share']"></plan-option>
    </div>

    <div v-if="tier != '0'">
      <h3 class="mt-8 mb-4">Plan Actions</h3>

      <div class="p-4 bg-white rounded-xl inline-block border">
        <ul>
          <li><btn :link="true" href="/account/update_payment_method">Update current payment method</btn></li>
          <li class="mt-2"><btn :link="true" href="/account/manage_payments">Change payment method, update billing info, and view invoices</btn></li>
          <li class="mt-2" v-if="activeUntil.length == 0"><btn :link="true" href="/account/cancel_subscription">Cancel your plan</btn></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['initTier', 'initPeriod', 'initActiveUntil'],
  data() {
    return {
      tier: this.initTier,
      period: this.initPeriod,
      activeUntil: this.initActiveUntil,
    }
  },
  components: {
  },
  mounted() {
  },
  methods: {
  },
}  
</script>
