<template>
  <div>
    <transition name="transition-fade">
      <alert v-if="showFlash" class="fixed bottom-0 z-40 right-0 mb-4 mr-4" type="success" :inline="true">Homepage saved</alert>
    </transition>

    <form class="simple_form form edit_homepage" id="edit_homepage">

      <div ref="errorSummary">
        <alert v-if="this.errors" type="error">
          Error(s) prevent changes from being saved: {{ errors }}
        </alert>
      </div>

      <form-heading title="Featured Videos" description="Choose which videos appear on your
        channel homepage. Drag to reorder. If no videos are selected, upcoming premieres and recently available videos will be displayed automatically." />

      <div class="mt-6">
        <draggable
          class="grid grid-cols-2 gap-6 lg:grid-cols-3 xl:grid-cols-4"
          tag="ul"
          role="list"
          draggable=".item"
          v-model="featuredVideos"
          @end="saveFeaturedVideos">
          <template v-if="featuredVideos.length">
            <item
              v-for="video in this.featuredVideos"
              :key="video.id"
              class="item cursor-move active:opacity-75"
              tag="li"
              :image-url="video.image_url"
              btn-icon="close"
              @btn-click="() => unfeatureVideo(video.id)">
              {{ video.name }}
            </item>
          </template>
          <item-empty
            slot="footer"
            v-if="unfeaturedVideos.length"
            description="Add featured video"
            @click="showAvailableFeaturedVideos = true"
          />
      </draggable>
        <slide-over
          title="Videos available to feature"
          :active.sync="showAvailableFeaturedVideos"
          :overlay-transparent="true">
          <ul v-if="this.unfeaturedVideos.length" role="list"
            class="grid grid-cols-2 sm:grid-cols-3 gap-6">
            <item
              v-for="video in this.unfeaturedVideos"
              :key="video.id"
              class="relative"
              tag="li"
              :image-url="video.image_url"
              btn-icon="plus"
              @btn-click="() => featureVideo(video.id)">
              {{ video.name }}
            </item>
          </ul>
          <alert v-else>All videos currently featured.</alert>
        </slide-over>
      </div>

      <form-heading class="mt-12" title="Featured Categories" description="Choose which categories appear in
        the featured list on your channel homepage. Drag to reorder." />
      
      <div class="mt-6">
        <draggable
          class="grid grid-cols-2 gap-6 lg:grid-cols-3 xl:grid-cols-4"
          tag="ul"
          role="list"
          draggable=".item"
          v-model="featuredCategories"
          @end="saveFeaturedCategories">
          <template v-if="featuredCategories.length">
            <item
              v-for="category in this.featuredCategories"
              :key="category.id"
              class="item cursor-move active:opacity-75"
              tag="li"
              :image-url="category.image_url"
              btn-icon="close"
              @btn-click="() => unfeatureCategory(category.id)">
              {{ category.name }}
            </item>
          </template>
          <item-empty
            slot="footer"
            v-if="unfeaturedCategories.length"
            description="Add featured category"
            @click="showAvailableFeaturedCategories = true"
          />
        </draggable>
        <slide-over
          title="Categories available to feature"
          :active.sync="showAvailableFeaturedCategories"
          :overlay-transparent="true">
          <ul v-if="this.unfeaturedCategories.length" role="list"
            class="grid grid-cols-2 sm:grid-cols-3 gap-6">
            <item
              v-for="category in this.unfeaturedCategories"
              :key="category.id"
              class="relative"
              tag="li"
              :image-url="category.image_url"
              btn-icon="plus"
              @btn-click="() => featureCategory(category.id)">
              {{ category.name }}
            </item>
          </ul>
          <alert v-else>All categories currently featured.</alert>
        </slide-over>
      </div>

      <form-heading class="mt-12" title="Highlighted Categories" description="Choose categories to appear on
        the bottom of your channel homepage. Recently available videos from each selected category will appear in a
        list. Drag to reorder." style="margin-top: 60px"/>

      <div class="mt-6">
        <draggable
          class="grid grid-cols-2 gap-6 lg:grid-cols-3 xl:grid-cols-4"
          tag="ul"
          role="list"
          draggable=".item"
          v-model="highlightedCategories"
          @end="saveHighlightedCategories">
          <template v-if="highlightedCategories.length">
            <item
              v-for="category in this.highlightedCategories"
              :key="category.id"
              class="item cursor-move active:opacity-75"
              tag="li"
              :image-url="category.image_url"
              btn-icon="close"
              @btn-click="() => unhighlightCategory(category.id)">
              {{ category.name }}
            </item>
          </template>
          <item-empty
            slot="footer"
            v-if="unhighlightedCategories.length"
            description="Add highlighted category"
            @click="showAvailableHighlightedCategories = true"
          />
        </draggable>
        <slide-over
          title="Categories available to highlight"
          :active.sync="showAvailableHighlightedCategories"
          :overlay-transparent="true">
          <ul v-if="this.unhighlightedCategories.length" role="list"
            class="grid grid-cols-2 sm:grid-cols-3 gap-6">
            <item
              v-for="category in this.unhighlightedCategories"
              :key="category.id"
              class="relative"
              tag="li"
              :image-url="category.image_url"
              btn-icon="plus"
              @btn-click="() => highlightCategory(category.id)">
              {{ category.name }}
            </item>
          </ul>
          <alert v-else>All categories currently highlighted.</alert>
        </slide-over>
      </div>

    </form>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  props: {
    initVideos: Array,
    initCategories: Array,
    initFeaturedVideos: Array,
    initFeaturedCategories: Array,
    initHighlightedCategories: Array,
  },
  data() {
    return {
      featuredVideos: this.initFeaturedVideos,
      featuredCategories: this.initFeaturedCategories,
      highlightedCategories: this.initHighlightedCategories,
      showAvailableFeaturedVideos: false,
      showAvailableFeaturedCategories: false,
      showAvailableHighlightedCategories: false,
      errors: null,
      showFlash: false,
    }
  },
  components: {
    draggable,
  },
  computed: {
    unfeaturedVideos() {
      let featuredVideoIds = this.featuredVideoIds()
      return this.initVideos.filter(video => !featuredVideoIds.includes(video.id))
    },
    unfeaturedCategories() {
      let featuredCategoryIds = this.featuredCategoryIds()
      return this.initCategories.filter(cat => !featuredCategoryIds.includes(cat.id))
    },
    unhighlightedCategories() {
      let highlightedCategoryIds = this.highlightedCategoryIds()
      return this.initCategories.filter(cat => !highlightedCategoryIds.includes(cat.id))
    }
  },
  methods: {
    featureVideo(videoId) {
      this.featuredVideos.push(this.initVideos.find(video => video.id === videoId))
      this.saveFeaturedVideos()
    },
    unfeatureVideo(videoId) {
      this.featuredVideos = this.featuredVideos.filter(video => video.id !== videoId)
      this.saveFeaturedVideos()
    },
    featureCategory(catId) {
      this.featuredCategories.push(this.initCategories.find(cat => cat.id === parseInt(catId)))
      this.saveFeaturedCategories()
    },
    unfeatureCategory(catId) {
      this.featuredCategories = this.featuredCategories.filter(cat => cat.id !== parseInt(catId))
      this.saveFeaturedCategories()
    },
    highlightCategory(catId) {
      this.highlightedCategories.push(this.initCategories.find(cat => cat.id === parseInt(catId)))
      this.saveHighlightedCategories()
    },
    unhighlightCategory(catId) {
      this.highlightedCategories = this.highlightedCategories.filter(cat => cat.id !== parseInt(catId))
      this.saveHighlightedCategories()
    },
    featuredVideoIds() {
      return this.featuredVideos.map(video => video.id)
    },
    featuredCategoryIds() {
      return this.featuredCategories.map(cat => cat.id)
    },
    highlightedCategoryIds() {
      return this.highlightedCategories.map(cat => cat.id)
    },
    saveFeaturedVideos() {
      let params = {
        format: "json",
        featured_video_ids: this.featuredVideoIds().join(", ")
      }
      this.postUpdate(params)
    },
    saveFeaturedCategories() {
      let params = {
        format: "json",
        featured_category_ids: this.featuredCategoryIds().join(", ")
      }
      this.postUpdate(params)
    },
    saveHighlightedCategories() {
      let params = {
        format: "json",
        highlighted_category_ids: this.highlightedCategoryIds().join(", ")
      }
      this.postUpdate(params)
    },
    postUpdate(params) {
      let requestParams = {
        method: 'post',
        url: '/homepage',
        data: params,
        headers: {
          'X-CSRF-Token': document.querySelectorAll(`meta[name="csrf-token"]`)[0].getAttribute('content')
        }
      }

      this.$http(requestParams)
        .then((response) => {
          if (response.data.success) {
            this.errors = null
            this.showFlash = true
            clearTimeout(this.cancelFlash)
            this.cancelFlash = setTimeout(() => this.showFlash = false, 2000)
          } else {
            this.showFlash = false
            this.errors = response.data.errors
            this.$refs.errorSummary.scrollIntoView()
          }
        }).catch((e) => {
          console.log(e)
        })
    },
  },
}
</script>
