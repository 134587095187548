<template>
  <div>
    <alert v-if="showFlash" class="mb-4" type="success" dismissible>Category saved.</alert>
    
    <form class="simple_form form edit_category" id="edit_category">    

      <form-heading title="Category Details" description="Configure the appearance of this category" />
      
      <p v-if="hasErrors('base')" style="color: #cc4b37;">{{errors.base[0]}}</p>

      <form-inputs>
      
        <div class="form-input string required category_name" v-bind:class="{ error: hasErrors('name') }">
          <label class="form-label string required" for="category_name">
            Name
            <abbr title="required">*</abbr>
          </label>
          <div class="form-input-main">
            <input v-model="name" autofocus="autofocus" class="string required" type="text" id="category_name" required />
            <small v-if="hasErrors('name')" small class="form-error">{{errors.name[0]}}</small>
          </div>
        </div>

        <div class="form-input string required category_description" v-bind:class="{ error: hasErrors('description') }">
          <label class="form-label string required" for="category_description">
            Description
          </label>
          <div class="form-input-main">
            <textarea v-model="description" rows="4" class="string required" id="category_description" required />
            <small v-if="hasErrors('description')" small class="form-error">{{errors.description[0]}}</small>
          </div>
        </div>
        
        <div class="form-input string required category_caption" v-bind:class="{ error: hasErrors('caption') }">
          <label class="form-label string required" for="category_caption">
            Caption
          </label>
          <div class="form-input-main">
            <input v-model="caption" class="string required" type="text" id="category_caption" required />
            <small v-if="hasErrors('caption')" small class="form-error">{{errors.caption[0]}}</small>
          </div>
        </div>

        <div class="form-input string required image_file" v-bind:class="{ error: !imageValid || hasErrors('image_url')}">
          <label class="form-label string required">
            Image
            <abbr title="required">*</abbr>
          </label>
          <div class="form-input-main">
            <div v-if="imageUrl.length > 0" class="mb-3">
              <img :src="imageUrl" class="rounded-md">
            </div>
            <dashboard-modal :uppy="uppy" :plugins="['ImageEditor']" :open="uploaderModalOpen" :props="dashboardModalOptions" />
            <btn variant="secondary" tag="button" :icon="false" accesskey="a" @click.native.prevent="openUploader">Choose Image</btn>
            <small v-if="hasErrors('image_url')" small class="form-error">{{errors.image_url[0]}}</small>
          </div>
        </div>

        <div class="form-input sort_by">
          <label class="form-label string" for="sort_by">
            Sort Videos By
          </label>
          <div class="form-input-main">
            <select v-model="sortBy">
              <option value="name">Name</option>
              <option value="available">Date Available</option>
            </select>
          </div>
        </div>
        
        <div class="form-input required boolean category_is_active">
          <label class="form-label">Active?</label>
          <div class="form-input-main">
            <div class="form-checkbox">
              <div class="form-toggle">
                <input v-model="isActive" id="category_is_active" type="checkbox" name="category_is_active">
                <label for="category_is_active" aria-hidden="true"></label>
              </div>
              <label class="form-label">Active?</label>
            </div>
            <small v-if="hasErrors('is_active')" small class="form-error">{{errors.is_active[0]}}</small>
            <small class="form-hint">Enable to publish this category.</small>
          </div>
        </div>

      </form-inputs>

      <form-actions>
        <btn v-if="featured || highlighted" variant="secondary" icon="xmark" tag="button"
                                                                             disabled="disabled">
          {{noDeleteError()}}
        </btn>
        <btn v-if="isUpdate && !featured && !highlighted" variant="secondary" icon="xmark" icon-placement="before" tag="button" accesskey="d" @click.native.prevent="deleteCategory" :disabled="isSubmitting">Delete</btn>
        <btn type="submit" :icon="false" tag="button" accesskey="s" @click.native.prevent="saveCategory" :disabled="isSubmitting">Save Category</btn>
      </form-actions>
      
    </form>
  </div>
</template>

<script>
import { DashboardModal } from '@uppy/vue'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/image-editor/dist/style.css'

import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import ImageEditor from '@uppy/image-editor'

export default {
  props: {
    initId: String,
    initName: String,
    initDescription: String,
    initCaption: String,
    initIsActive: Boolean,
    initShowFlash: Boolean,
    initImageUrl: String,
    initSortBy: String,
    featured: Boolean,
    highlighted: Boolean,
  },
  data() {
    return {
      id: this.initId,
      name: this.initName || '',
      description: this.initDescription || '',
      imageUrl: this.initImageUrl || '',
      sortBy: this.initSortBy,
      caption: this.initCaption || '',
      isActive: this.initIsActive,
      isUpdate: false,
      errors: null,
      showFlash: this.initShowFlash,
      isSubmitting: false,
      imageValid: true,
      uploaderModalOpen: false,
      dashboardModalOptions: {
        onRequestCloseModal: this.closeUploader,
        closeAfterFinish: true,
        showLinkToFileUploadResult: false,
        note: "Image should be 16:9 ratio. Click the pencil icon to crop if necessary.",
        locale: {
          strings: {
            dropPaste: 'Drop image file here, paste or %{browse}',
          }
        }
      }
    }
  },
  components: {
    DashboardModal,
  },
  computed: {
    uppy: function () {
      return new Uppy({
        allowMultipleUploads: false,
        restrictions: {
          allowedFileTypes: ['image/*'],
          minNumberOfFiles: 1,
          maxNumberOfFiles: 1,
          maxTotalFileSize: 10485760
        }
      }).use(ImageEditor, {
        id: 'ImageEditor',
        quality: 0.8,
        actions: {
          revert: false,
          rotate: false,
          flip: false,
          zoomIn: true,
          zoomOut: true,
          cropSquare: false,
          cropWidescreen: false,
          cropWidescreenVertical: false
        },
        cropperOptions: {
          viewMode: 1,
          background: false,
          autoCropArea: 1,
          responsive: true,
          aspectRatio: 16/9,
        },
      }).use(AwsS3Multipart, {
        companionUrl: '/',
      }).on('upload-success', (file, response) => {
        // console.log("file upload success")
        // console.log("file: ", JSON.stringify(file))
        // console.log("response: ", JSON.stringify(response))
        this.imageUrl = response.uploadURL
      })
    },
  },
  beforeDestroy() {
    this.uppy.close()
  },
  mounted() {
    this.isUpdate = (this.id != undefined)
  },
  methods: {
    noDeleteError() {
      if (this.featured && !this.highlighted) {
        return "Category featured on homepage, cannot delete"
      } else if (!this.featured && this.highlighted) {
        return "Category highlighted on homepage, cannot delete"
      } else {
        return "Category featured and highlighted on homepage, cannot delete"
      }
    },
    openUploader() {
      this.uploaderModalOpen = true
    },
    closeUploader() {
      this.uploaderModalOpen = false
    },
    saveCategory() {
      this.isSubmitting = true
      
      let params = {
        category: {
          name: this.name,
          description: this.description,
          caption: this.caption,
          is_active: this.isActive,
          image_url: this.imageUrl,
          sort_by: this.sortBy,
        }
      }
      
      let requestParams = {
        method: (this.isUpdate) ? 'put' : 'post',
        url: (this.isUpdate) ? `/categories/${this.id}` : '/categories',
        data: params,
        headers: {
          'X-CSRF-Token': document.querySelectorAll(`meta[name="csrf-token"]`)[0].getAttribute('content')
        }
      }
      
      this.$http(requestParams)
        .then((response) => {
          if (response.data.success) {
            this.errors = null
            if (this.id) { // edit
              this.showFlash = true
              window.scrollTo(0,0)
            } else { // new
              window.location = "/categories/" + response.data.category_id + "?f=1"
            }
          } else {
            this.showFlash = false
            this.errors = response.data.errors
          }
          this.isSubmitting = false
        }).catch((e) => {
          console.log(e)
        })
    },
    hasErrors(field) {
      return (this.errors && this.errors[field]);
    },
    deleteCategory() {
      
      if (confirm("Are you sure?")) {
        
        this.isSubmitting = true
        
        let params = {
          category: {
            id: this.id,
          }
        }
        
        let requestParams = {
          method: 'delete',
          url: `/categories/${this.id}`,
          data: params,
          headers: {
            'X-CSRF-Token': document.querySelectorAll(`meta[name="csrf-token"]`)[0].getAttribute('content')
          }
        }
        
        this.$http(requestParams)
          .then((response) => {
            if (response.data.success) {
              this.errors = null
              window.location.href = `/categories`
            } else {
              this.errors = response.data.errors
              this.isSubmitting = false
            }
        }).catch((e) => {
          console.log(e)
        })

      }
      
    }
  },
}  
</script>
