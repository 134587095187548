<template>
  <div>
    <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
    <transition :duration="300">
      <div v-show="sidebarIsOpen" class="fixed inset-0 flex z-50 md:hidden" aria-modal="true" role="dialog">
        <!--
          Off-canvas menu overlay, show/hide based on off-canvas menu state.
          
          Entering: "transition-opacity ease-linear duration-300"
          From: "opacity-0"
          To: "opacity-100"
          Leaving: "transition-opacity ease-linear duration-300"
          From: "opacity-100"
          To: "opacity-0" -->
        <transition
          enter-active-class="transition-opacity ease-linear duration-300"
          enter-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="transition-opacity ease-linear duration-300"
          leave-to-class="opacity-0"
        >
          <div v-show="sidebarIsOpen" class="fixed inset-0 bg-neutral-600 bg-opacity-75" aria-hidden="true" @click="sidebarIsOpen = false" />
        </transition>
        <!--
          Off-canvas menu, show/hide based on off-canvas menu state.
          
          Entering: "transition ease-in-out duration-300 transform"
          From: "-translate-x-full"
          To: "translate-x-0"
          Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "-translate-x-full" -->
        <transition
          enter-active-class="transition ease-in-out duration-300 transform"
          enter-class="-translate-x-full"
          enter-to-class="translate-x-0"
          leave-active-class="transition ease-in-out duration-300 transform"
          leave-to-class="-translate-x-full"
        >
          <div v-show="sidebarIsOpen" class="relative flex-1 flex flex-col max-w-xs w-full bg-black">
            <!--
              Close button, show/hide based on off-canvas menu state.
              
              Entering: "ease-in-out duration-300"
              From: "opacity-0"
              To: "opacity-100"
              Leaving: "ease-in-out duration-300"
              From: "opacity-100"
              To: "opacity-0"-->
            <transition
              enter-active-class="transition-opacity ease-in-out duration-300"
              enter-class="opacity-0"
              enter-to-class="opacity-100"
              leave-active-class="transition-opacity ease-in-out duration-300"
              leave-to-class="opacity-0"
            >
              <div v-show="sidebarIsOpen" class="absolute top-0 right-0 -mr-12 pt-2">
                <button class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" type="button" @click="sidebarIsOpen = false">
                  <span class="sr-only">Close sidebar</span>
                  <font-awesome-icon class="h-6 w-6 text-white" :icon="['fas', 'xmark']" />
                </button>
              </div>
            </transition>
            <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <div class="px-4">
                <a href="/">
                  <pennant-logo class="h-8 w-auto text-white" />
                </a>
                <div class="-mt-1 pl-38px text-primary text-xs font-medium tracking-wider uppercase">
                  {{ tenantName }}
                </div>
              </div>
              <application-nav class="mt-5 px-2 space-y-1" :links="links" />
            </div>
            <div class="flex-shrink-0 flex bg-neutral-900 p-4">
              <a v-if="currentUser" class="flex-shrink-0 w-full block group" :href="$root.Routes.destroy_user_session_path()" data-method="delete">
                <p class="text-base font-medium text-neutral-400 group-hover:text-white truncate">
                  {{ currentUser.email }}
                </p>
                <p class="font-medium text-neutral-500 group-hover:text-white">
                  Log out
                </p>
              </a>
            </div>
          </div>
        </transition>
        <div class="flex-shrink-0 w-14">
          <!-- Force sidebar to shrink to fit close icon -->
        </div>
      </div>
    </transition>
    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex-1 flex flex-col min-h-0 bg-black">
        <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
          <div class="px-4">
            <a href="/">
              <pennant-logo class="h-8 w-auto text-white" />
            </a>
            <div class="-mt-1 pl-38px text-primary text-xs font-medium tracking-wider uppercase">
              {{ tenantName }}
            </div>
          </div>
          <application-nav class="mt-5 flex-1 px-2 space-y-1" :links="links" />
        </div>
        <div class="flex-shrink-0 flex bg-neutral-900 p-4">
          <a v-if="currentUser" class="flex-shrink-0 w-full block group" :href="$root.Routes.destroy_user_session_path()" data-method="delete">
            <p class="font-medium text-neutral-400 group-hover:text-white truncate">
              {{ currentUser.email }}
            </p>
            <p class="text-xs font-medium text-neutral-500 group-hover:text-white">
              Log out
            </p>
          </a>
        </div>
      </div>
    </div>
    <div class="md:pl-64 flex flex-col flex-1">
      <div class="sticky top-0 z-40 flex items-center md:hidden p-1 sm:p-2 bg-black">
        <button class="h-12 w-12 inline-flex items-center justify-center rounded-md text-neutral-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500" type="button" @click="sidebarIsOpen = true">
          <span class="sr-only">Open sidebar</span>
          <font-awesome-icon class="h-6 w-6" :icon="['fas', 'bars']" />
        </button>
        <a href="/">
          <pennant-logo class="ml-1 h-6 w-auto text-white" />
        </a>
      </div>
      <main class="flex-1">
        <div v-if="pageHeading" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-6">
          <page-heading v-bind="pageHeading">
            <slot name="page-heading" />
          </page-heading>
        </div>
        <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 pt-4 sm:pt-6 md:pt-8 pb-8 sm:pb-12 md:pb-16">
          <slot />
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import PennantLogo from '../images/pennant-logo.svg'
import ApplicationNav from './application_nav.vue'
import PageHeading from './page_heading.vue'

export default {
  components: {
    PennantLogo,
    ApplicationNav,
    PageHeading
  },
  props: {
    currentUser: Object,
    currentUserIsTenantRole: Boolean,
    links: Array,
    pageHeading: Object
  },
  data() {
    return {
      sidebarIsOpen: false
    }
  },
  computed: {
    tenantName() {
      return this.currentUserIsTenantRole
        ? this.currentUser.tenant.name
        : 'Admin'
    }
  }
}
</script>
