<template>
  <div>
    <form class="simple_form form live_stream" id="live_stream">

      <div ref="errorSummary">
        <alert v-if="hasErrors('base')" class="mb-4" type="error" dismissible>
          {{ errors.base[0] }}
        </alert>
        <alert v-if="this.errors && !hasErrors('base')" class="mb-4" type="error" dissmissable>
          Error(s) prevent changes from being saved
        </alert>
      </div>

      <div :class="{ 'grid lg:grid-cols-12 gap-8': $slots.default} ">

        <div v-if="$slots.default" class="relative lg:col-span-4 lg:order-2">
          <div class="sticky top-4 bg-neutral-200 rounded-lg p-4">
            <slot />
          </div>
        </div>
      
        <div :class="{ 'lg:col-span-8': $slots.default }">

          <form-heading title="Live Stream Details" description="" />

          <form-inputs>
              
            <div class="form-input string required video_name" v-bind:class="{ error: hasErrors('name') }">
              <label class="form-label string required" for="video_name">
                Name
                <abbr title="required">*</abbr>
              </label>
              <div class="form-input-main">
                <input v-model="name" autofocus="autofocus" class="string required" type="text" id="video_name" required />
                <small v-if="hasErrors('name')" small class="form-error">{{errors.name[0]}}</small>
              </div>
            </div>

            <div v-if="!isLegacy" class="form-input string required video_description" v-bind:class="{ error: hasErrors('description') }">
              <label class="form-label string required" for="video_description">
                Description
                <abbr title="required">*</abbr>
              </label>
              <div class="form-input-main">
                <textarea v-model="description" rows="4" class="string required" id="video_description" required />
                <small v-if="hasErrors('description')" small class="form-error">{{errors.description[0]}}</small>
              </div>
            </div>

            <div v-if="!isLegacy" class="form-input string video_price" v-bind:class="{ error: hasErrors('price') }">
              <label class="form-label string" for="video_price">
                Premium Price
              </label>
              <div class="form-input-main">
                <input v-model="price" autofocus="autofocus" class="xs string" type="text"
                id="video_price" required placeholder="4.95" :disabled="purchases" />
                <small v-if="hasErrors('price')" small class="form-error">{{errors.price[0]}}</small>
                <div class="form-hint prose">
                  <p>The final amount deposited in your account will be reduced by:</p>
                  <ol class="ml-2">
                    <li>Stripe's fee of 2.9% + $0.30</li>
                    <li>Pennant's fee (varies based on your plan)</li>
                  </ol>
                  <!-- <p>For example, if the customer price is $4.95, the Stripe fee will be $0.44, and -->
                  <!-- the Pennant fee is always $1.00, resulting in $3.51 deposited to your account.</p> -->
                </div>
              </div>
            </div>

            <div v-if="!isLegacy" class="form-input categories">
              <label class="form-label string" for="categories">
                Categories
              </label>
              <div class="form-input-main">
                <vue-tags-input
                  v-model="tag"
                  :tags="tags"
                  placeholder="Add Category"
                  :autocomplete-items="autocompleteItems"
                  :add-only-from-autocomplete="true"
                  @tags-changed="newTags => tags = newTags" />
              </div>
            </div>
            
            <div class="form-input string required image_file" v-bind:class="{ error: !imageValid || hasErrors('image_url')}">
              <label class="form-label string required">
                Preview Image
                <abbr title="required">*</abbr>
              </label>
              <div class="form-input-main">
                <div v-if="imageUrl.length > 0" class="mb-3">
                  <img :src="imageUrl" class="rounded-md" />
                </div>
                <dashboard-modal :uppy="uppy" :plugins="['ImageEditor']" :open="uploaderModalOpen" :props="dashboardModalOptions" />
                <btn variant="secondary" :icon="false" tag="button" accesskey="a" @click.native.prevent="openUploader">Choose Image</btn>
                <small v-if="hasErrors('image_url')" small class="form-error">{{errors.image_url[0]}}</small>
              </div>
            </div>

            <div v-if="!isLegacy" class="form-input shopify_custom_collection">
              <label class="form-label string">
                Shopify Collection
              </label>
              <div class="form-input-main">
                <div v-if="shopifyCollections.length > 0">
                  <select v-model="shopifyCollection">
                    <option value="0">No Collection</option>
                    <option v-for="c in shopifyCollections" :key="c.id" :value="c.id">
                      {{ c.title }}
                    </option>
                  </select>
                </div>
                <alert v-else inline>No collections set up yet.</alert>
              </div>
            </div>

            <div v-if="!isLegacy" class="form-input required video_available" v-bind:class="{ error: hasErrors('date_available') }">
              <label class="form-label string required" for="video_available">
                Available Starting (Local Time)
                <abbr title="required">*</abbr>
              </label>
              <div class="form-input-main">
                <datetime v-model="dateAvailable" type="datetime" :minute-step="15" input-class="sm datetime-picker" ></datetime>
                <small v-if="hasErrors('date_available')" small class="form-error">{{errors.date_available[0]}}</small>
              </div>
            </div>

            <div v-if="!isLegacy" class="form-input boolean required video_published switch">
              <label class="form-label">Published</label>
              <div class="form-input-main">
                <div class="form-checkbox">
                  <div class="form-toggle">
                    <input v-model="published" id="enabledSwitch" type="checkbox" name="enabledSwitch">
                    <label for="enabledSwitch" />
                  </div>
                </div>
                <small v-if="hasErrors('published')" small class="form-error">{{errors.published[0]}}</small>
                <small class="form-hint">Enable to publish this live stream.</small>
              </div>
            </div>

          </form-inputs>

        </div>
      
      </div>

      <div v-if="errors && errors.delete" style="color: red">{{errors.delete}}</div>

      <form-actions>
        <btn v-if="isUpdate && published" variant="secondary" icon="circle-exclamation" tag="button" disabled="disabled">Stream is published, cannot delete</btn>
        <btn v-if="isUpdate && purchases" variant="secondary" icon="circle-exclamation" tag="button" disabled="disabled">Stream has purchases, cannot delete</btn>
        <btn v-if="isUpdate && featured" variant="secondary" icon="circle-exclamation" tag="button" disabled="disabled">Stream featured on homepage, cannot delete</btn>
        <btn v-if="isUpdate && !published && !purchases && !featured" icon="xmark" icon-placement="before" variant="secondary" tag="button" accesskey="d" @click.native.prevent="deleteVideo" :disabled="isSubmitting">Delete</btn>
        <btn type="submit" :icon="false" tag="button" accesskey="s" @click.native.prevent="saveVideo" :disabled="isSubmitting">{{isUpdate ? 'Update Live Stream' : 'Create Live Stream'}}</btn>
      </form-actions>
      
    </form>
  </div>
</template>

<script>
import { DashboardModal } from '@uppy/vue'
import VueTagsInput from '@johmun/vue-tags-input'
import { Datetime } from 'vue-datetime'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/image-editor/dist/style.css'
import 'vue-datetime/dist/vue-datetime.css'

import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import ImageEditor from '@uppy/image-editor'

export default {
  props: {
    initIsLegacy: Boolean,
    initCategories: Array,
    initCategoriesSelected: Array,
    initId: String,
    initName: String,
    initDescription: String,
    initIsSecure: Boolean,    
    initImageUrl: String,
    initDateAvailable: String,
    initPublished: Boolean,
    initPrice: String,
    initPurchases: Boolean,
    initShopifyCollection: Number,
    initShopifyCollections: Array,
    featured: Boolean,
  },
  data() {
    return {
      isUpdate: false,
      isLegacy: this.initIsLegacy || false,
      id: this.initId,
      name: this.initName || '',
      description: this.initDescription || '',
      isSecure: this.initIsSecure,
      imageUrl: this.initImageUrl || '',
      dateAvailable: this.initDateAvailable || '',
      published: this.initPublished,
      price: this.initPrice || '',
      purchases: this.initPurchases || false,
      shopifyCollection: this.initShopifyCollection,
      shopifyCollections: this.initShopifyCollections || [],
      tag: '',
      tags: this.initCategoriesSelected || [],
      autocompleteItems: this.initCategories,      
      errors: null,
      showFlash: false,
      isSubmitting: false,
      imageValid: true,
      uploaderModalOpen: false,
      dashboardModalOptions: {
        onRequestCloseModal: this.closeUploader,
        closeAfterFinish: true,
        showLinkToFileUploadResult: false,
        note: "Image should be 16:9 ratio. Click the pencil icon to crop if necessary.",
        locale: {
          strings: {
            dropPaste: 'Drop image file here, paste or %{browse}',
          }
        }
      }
    }
  },
  components: {
    DashboardModal,
    VueTagsInput,
    Datetime,
  },
  computed: {
    uppy: function () {
      return new Uppy({
        allowMultipleUploads: false,
        restrictions: {
          allowedFileTypes: ['image/*'],
          minNumberOfFiles: 1,
          maxNumberOfFiles: 1,
          maxTotalFileSize: 10485760
        }
      }).use(ImageEditor, {
        id: 'ImageEditor',
        quality: 0.8,
        actions: {
          revert: false,
          rotate: false,
          flip: false,
          zoomIn: true,
          zoomOut: true,
          cropSquare: false,
          cropWidescreen: false,
          cropWidescreenVertical: false
        },
        cropperOptions: {
          viewMode: 1,
          background: false,
          autoCropArea: 1,
          responsive: true,
          aspectRatio: 16/9,
        },
      }).use(AwsS3Multipart, {
        companionUrl: '/',
      }).on('upload-success', (file, response) => {
        // console.log("file upload success")
        // console.log("file: ", JSON.stringify(file))
        // console.log("response: ", JSON.stringify(response))
        this.imageUrl = response.uploadURL
      })
    },
  },
  beforeDestroy() {
    this.uppy.close()
  },
  mounted() {
    this.isUpdate = (this.id != undefined)
  },
  methods: {
    openUploader() {
      this.uploaderModalOpen = true
    },
    closeUploader() {
      this.uploaderModalOpen = false
    },
    getCatVideoAttrs() {
      // this.initCategoriesSelected is the array of exisiting categories
      //  [ {text: 'cat1', id: 3, category_id: 1}, {text: 'cat2', id: 4, category_id: 2} ]
      // this.initCategories is the array of all categories
      //  [ {text: 'cat1', category_id: 1}, {text: 'cat2', category_id: 2}, {text: 'cat3', category_id: 3} ]
      
      let arr = []
      const tagIds = this.tags.map(e => { return e.category_id })
      const oldIds = this.initCategoriesSelected.map(e => { return e.category_id })
      
      // deletions
      this.initCategoriesSelected.map(e => {
        if (tagIds.indexOf(e.category_id) == -1) {
          arr.push( { id: e.id, _destroy: '1'} )
        }
      })

      // additions
      this.tags.map(e => {
        if (oldIds.indexOf(e.category_id) == -1) {
          arr.push( { category_id: e.category_id } )
        }
      })
      
      return arr
    },
    saveVideo() {
      this.isSubmitting = true

      let catVideoAttrs = this.getCatVideoAttrs()

      let params = (this.isLegacy) ? {
        video: {
          name: this.name,
          image_url: this.imageUrl,
        }
      } : {
        video: {
          name: this.name,
          description: this.description,
          image_url: this.imageUrl,
          published: this.published,
          date_available: this.dateAvailable,
          price_cents: (this.price.trim().length == 0) ? 0 : this.price * 100,
          shopify_custom_collection_id: (this.shopifyCollection == 0) ? null : this.shopifyCollection
        }
      }

      if (catVideoAttrs.length > 0) {
        params['video']['category_videos_attributes'] = catVideoAttrs
      }
      
      let requestParams = {
        method: (this.isUpdate) ? 'put' : 'post',
        url: (this.isUpdate) ? `/live_streams/${this.id}` : '/live_streams',
        data: params,
        headers: {
          'X-CSRF-Token': document.querySelectorAll(`meta[name="csrf-token"]`)[0].getAttribute('content')
        }
      }
      
      this.$http(requestParams)
        .then((response) => {
          if (response.data.success) {
            this.errors = null
            // window.location.href = `/live_streams/${response.data.video_id}/edit`
            window.location.href = `/live_streams`
          } else {
            this.showFlash = false
            this.errors = response.data.errors
            this.$refs.errorSummary.scrollIntoView()
          }
          this.isSubmitting = false
        }).catch((e) => {
          console.log(e)
        })
    },
    hasErrors(field) {
      return (this.errors && this.errors[field]);
    },
    deleteVideo() {
      if (confirm("Are you sure?")) {
        
        this.isSubmitting = true
        
        let params = {
          format: 'json',
          video: {
            id: this.id,
          }
        }
        
        let requestParams = {
          method: 'delete',
          url: `/live_streams/${this.id}`,
          data: params,
          headers: {
            'X-CSRF-Token': document.querySelectorAll(`meta[name="csrf-token"]`)[0].getAttribute('content')
          }
        }
        
        this.$http(requestParams)
          .then((response) => {
            if (response.data.success) {
              this.errors = null
              window.location.href = `/live_streams`
            } else {
              this.errors = response.data.errors
              this.isSubmitting = false
            }
        }).catch((e) => {
          console.log(e)
        })
      }
    },   
    detailsPath() {
      return `/live_streams/${this.id}/details`
    },
  },
}  
</script>

