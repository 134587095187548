<template>
  <div class="flex items-center justify-between flex-col space-y-2">
    <div class="flex space-x-3 items-center justify-center">
      <font-awesome-icon :class="`h-${iconSize} w-${iconSize}`" :icon="['fas', icon]"></font-awesome-icon>
      <div class="text-2xl font-bold">{{topText}}</div>
    </div>
    <div class="text-lg font-medium">
      {{bottomText}}
      <!-- TODO: help text / info -->
    </div>
  </div>
</template>

<script>

export default {
  props: {
    icon: String,
    iconSize: String,
    topText: String,
    bottomText: String,
    helpText: String
  },
  data() {
    return {
    }
  },
  components: {
  },
  computed: {
  },
  methods: {
  },
}
</script>

