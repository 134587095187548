import videojs from 'video.js'
import "videojs-mux"
import 'videojs-vr/dist/videojs-vr.min.js'
import airplay from '@silvermine/videojs-airplay'
import chromecast from '@silvermine/videojs-chromecast'
airplay(videojs)
chromecast(videojs)

export const videoSetup = () => {
  var el = document.getElementById('video')
  
  if (el) {

    const muxKey = el.getAttribute('data-mkey')
    const videoTitle = el.getAttribute('data-title')
    const videoSlug = el.getAttribute('data-slug')
    const videoStreamType = el.getAttribute('data-stream-type')
    const duration = el.getAttribute('data-duration')
    
    const options = {
      plugins: {
        airPlay: {},
        chromecast: {},
        mux: {
          debug: false,
          data: {
            env_key: muxKey,
            // Metadata
            video_id: videoSlug,
            video_title: videoTitle,
            video_stream_type: videoStreamType,
            video_duration: duration,
          }
        }        
      }
    }
    var player = videojs('video', options)
  }
  // player.vr({projection: '180', forceCardboard: true, debug: true});

  const copyButton = document.getElementById('copy-embed')
  if (copyButton) {
    const ta = document.getElementById('embed-code')
    copyButton.addEventListener('click', (e) => {
      ta.select()
      document.execCommand('copy')
    })
  }
}
