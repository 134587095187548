<template>
  <div class="h-[578px] rounded-lg pt-4 pb-4 mt-4 bg-white xl:basis-2/3 relative">    
    <div class="flex items-center px-6">
      <font-awesome-icon class="h-5 w-5 mr-2" :icon="['fas', 'play']" />
      <div class="text-lg font-medium">Top Videos</div>
    </div>    
    <table class="tabl min-w-full divide-y divide-neutral-200 mt-6 border-b border-neutral-200">
      <thead class="bg-white">
        <tr>
          <th class="w-1/2" @click="sortVideos('name')">
            Name
            <font-awesome-icon v-if="sortField == 'name'" :icon="['fas', 'sort']" />
          </th>
          <th @click="sortVideos('views')">
            Views
            <font-awesome-icon v-if="sortField == 'views'" :icon="['fas', 'sort']" />
          </th>
          <th @click="sortVideos('mins_watched')">
            Minutes Watched
            <font-awesome-icon v-if="sortField == 'mins_watched'" :icon="['fas', 'sort']" />
          </th>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-neutral-200 text-neutral-500">
        <tr v-for="video in currentVideos" :key="video.slug">
          <td>
            <div class="flex items-center">
              <div class="h-12 w-20 mr-4 bg-neutral-50 bg-cover bg-center rounded-md shadow-inner" :style="'background-image: url(' + video.img_url + ')'"></div>
              <div>{{video.name}}</div>
            </div>
            
          </td>
          <td>{{video.views}}</td>
          <td>{{video.mins_watched}} minutes</td>
        </tr>
      </tbody>
    </table>
    <div v-if="currentVideos.length == 0" class="absolute top-[320px] left-0 text-neutral-400 text-lg w-full text-center">Viewed videos will appear here</div>
    <div v-if="canPage" class="px-6 mt-4 absolute bottom-4">
      <div class="w-24 flex">
        <div class="basis-1/2 py-2 flex justify-center items-center border-y border-l rounded-l-md"
             :class="canPagePrev ? 'border-neutral-300 border-r' : 'border-neutral-200'"
             @click="pagePrev()">
          <font-awesome-icon class="block" :class="canPagePrev ? 'text-neutral-500' : 'text-neutral-200'" :icon="['fas', 'chevron-left']" />
        </div>
        <div class="basis-1/2 py-2 flex justify-center items-center border-y border-r rounded-r-md"
             :class="canPageNext ? 'border-neutral-300 border-l' : 'border-neutral-200'"
             @click="pageNext()">
          <font-awesome-icon class="block" :class="canPageNext ? 'text-neutral-500' : 'text-neutral-200'" :icon="['fas', 'chevron-right']" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

const PAGE_SIZE = 5

export default {
  props: {
    videos: Array,
  },
  data() {
    return {
      sortBy: 'watched',
      currentPage: 1,
      currentVideos: [],
      sortField: 'mins_watched',
    }
  },
  components: {
  },
  mounted() {
    this.sortVideos('mins_watched')    
  },
  computed: {
    canPage() {
      return (this.canPagePrev || this.canPageNext)
    },
    canPagePrev() {
      return (this.currentPage > 1)
    },
    canPageNext() {
      return (this.videos[PAGE_SIZE * this.currentPage] != null)
    }
  },
  methods: {
    sortVideos(fieldName) {
      this.sortField = fieldName

      let sortMethod = (fieldName == 'name') ? this.alphaSort : this.numericalSort
      
      this.videos = this.videos.sort(sortMethod)
      this.currentPage = 1
      this.setCurrentVideos()
    },
    setCurrentVideos() {
      const idxStart = (this.currentPage - 1) * PAGE_SIZE
      const idxEnd   = this.currentPage * PAGE_SIZE
      this.currentVideos = this.videos.slice(idxStart, idxEnd) // 0,5 ... 5,10 ... 10,15
    },
    pageNext() {
      if (!this.canPageNext) {
        return
      }

      this.currentPage += 1
      this.setCurrentVideos()
    },
    pagePrev() {
      if (!this.canPagePrev) {
        return
      }
      
      this.currentPage -= 1
      this.setCurrentVideos()
    },
    numericalSort(a, b) {
      return b[this.sortField] - a[this.sortField]
    },
    alphaSort(a, b) {
      const A = a[this.sortField].toUpperCase()
      const B = b[this.sortField].toUpperCase()
      if (A < B) {
        return -1;
      }
      if (A > B) {
        return 1;
      }

      return 0;
    },
  },
}
</script>

