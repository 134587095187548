<template>
  <component :is="tag || 'div'">
    <div class="relative">
      <div class="block w-full aspect-w-16 aspect-h-9 rounded-lg bg-neutral-100 overflow-hidden">
        <img v-if="imageUrl" :src="imageUrl" class="object-cover" />
      </div>
      <btn v-if="btnIcon" class="absolute right-0 top-0 mt-4 mr-4 hover:bg-primary focus:ring-offset-0 focus:ring-primary" :icon="btnIcon" variant="secondary" tag="button" @click.prevent.native="$emit('btn-click')" />
    </div>
    <p v-if="$slots.default" class="mt-2 block text-sm font-medium text-neutral-900 text-center truncate">
      <slot />
    </p>
  </component>
</template>

<script>
export default {
  props: {
    imageUrl: String,
    btnIcon: String,
    tag: String
  }
}
</script>
