<template>
  <div :class="style">
    <div class="text-base font-medium">{{name}}</div>    
    <div class="py-8 pl-1 flex">
      <div class="shrink">
        <span v-if="price != 'contact'" class="font-bold text-3xl leading-7" >${{price}}</span>
        <span v-else class="text-2xl leading-7 text-neutral-700">Contact Us</span>
      </div>
      <div v-if="price != 'contact'" class="text-neutral-500 shrink pl-2 flex items-center">
        / USD month
      </div>
    </div>
    <form ref="form" method="post" action="/account/plan_select">
      <input ref="token" type="hidden" name="authenticity_token" value="" autocomplete="off">
      <input name="tier" type="hidden" v-model="tier">
      <input name="period" type="hidden" v-model="period">
      <btn v-if="isSelected && tier != '4'" variant="secondary" icon="" class="my-2 w-full hover:bg-neutral-200 cursor-default" :disabled="true">Select Plan</btn>
      <btn v-if="!isSelected && tier != '4'" icon="" class="my-2 w-full" @click.native.prevent="selectPlan">Select Plan</btn>
      <a v-if="tier == '4'" icon="" class="button my-2 w-full" href="mailto:support@pennant.tv?subject=The Palace Plan">Contact Us</a>
    </form>
    <div class="py-8 text-neutral-500 leading-7">{{description}}</div>
    <div v-for="item in features">
      <div v-if="item == 'Web + Apps'" class="py-4 border-t border-neutral-200">{{item}}<sup>*</sup></div>
      <div v-else class="py-4 border-t border-neutral-200">{{item}}</div>
    </div>
    <div v-if="features.includes('Web + Apps')" class="py-4 text-neutral-300"><sup>*</sup>when available</div>
  </div>
</template>

<script>
export default {
  props: {
    isSelected: Boolean,
    name: String,
    initTier: String,
    initPeriod: String,
    price: String,
    description: String,
    features: Array,
  },
  data() {
    return {
      style: "",
      tier: this.initTier,
      period: this.initPeriod,
    }
  },
  methods: {
    selectPlan() {      
      this.$refs.form.submit()
    },
  },
  beforeMount() {
    this.style = (this.isSelected) ? "basis-72 bg-white border-3 border-brand-blue rounded-2xl p-4 mb-4" :
      "basis-72 bg-white border border-neutral-200 rounded-2xl p-4 mb-4"
  },
  mounted() {
    // set CSRF token in hidden form
    this.$refs.token.value = document.querySelectorAll(`meta[name="csrf-token"]`)[0].getAttribute('content')
  }
}
</script>
