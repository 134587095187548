<template>
  <div>
    <alert v-if="showFlash" class="mb-4" type="success" dismissible>Video saved.</alert>
    
    <form class="simple_form form edit_video" id="edit_video">

      <div class="grid lg:grid-cols-12 gap-8">

        <div class="relative lg:col-span-4 lg:order-2">
          <btn v-if="!preparingStatus && videoUrl" class="mb-4" variant="secondary" :link="true" tag="a" :href="`/videos/${this.id}/replacement`">Replace Video</btn>
          <div class="sticky top-4 bg-neutral-200 rounded-lg p-4">            
            <alert v-if="preparingStatus && !videoUrl">Main video processing. Reload shortly to check status.</alert>
            <alert v-else-if="preparingStatus && videoUrl">Video has been changed but has not finished processing. Reload shortly to check status.</alert>
            <video v-if="!preparingStatus && videoUrl" ref="mainVideojs" class="video video-js vjs-fluid vjs-big-play-centered"
                  controls="true"
                  allowfullscreen="true"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  x-webkit-airplay='allow'>
              <source :src="videoUrl" type="application/x-mpegURL" />
            </video>

            <form-inputs class="stacked previewControls mt-4 border-t border-t-neutral-300 pt-4">
              <h3>Preview</h3>

              <alert v-if="previewProcessing">Preview video processing. Reload in a few seconds to check status.</alert>
              <template v-else-if="!previewProcessing && !previewUrl">
                <div class="form-input preview_start">
                  <label class="form-label string" for="preview_start">
                    Start Time for Preview (in seconds)
                  </label>
                  <div class="form-input-main">
                    <input v-model="previewStart" class="xs string" type="text" id="preview_start" />
                    <small v-if="hasErrors('preview_start')" small class="form-error">{{errors.preview_start[0]}}</small>
                  </div>
                </div>
                <div class="form-input preview_end">
                  <label class="form-label string" for="preview_end">
                    End Time for Preview (in seconds)
                  </label>
                  <div class="form-input-main">
                    <input v-model="previewEnd" class="xs string" type="text" id="preview_end" />
                    <small v-if="hasErrors('preview_end')" small class="form-error">{{errors.preview_end[0]}}</small>
                  </div>
                </div>
              </template>
              <template v-else-if="previewUrl">
                <video ref="previewVideojs" class="video video-js vjs-fluid vjs-big-play-centered"
                                            controls="true" allowfullscreen="true"
                                                            webkitallowfullscreen="true"
                                                            mozallowfullscreen="true"
                                                            x-webkit-airplay='allow'>
                  <source :src="previewUrl" type="application/x-mpegURL" />
                </video>
                <button class="button-secondary mt-4" accesskey="d" @click.prevent="deletePreview" :disabled="isSubmitting">
                  Delete Preview
                </button>
              </template>
            </form-inputs>
          </div>
        </div>

        <div class="lg:col-span-8">

          <form-heading title="Video Details" description="Configure the information that will
            appear for this video" />
          
          <div ref="errorSummary">
            <p v-if="hasErrors('base')" style="color: #cc4b37;">{{errors.base[0]}}</p>
            <p v-if="this.errors && !hasErrors('base')" style="color: #cc4b37;">Error(s) prevent changes from being saved</p>
          </div>

          <form-inputs>
          
            <div class="form-input string required video_name" v-bind:class="{ error: hasErrors('name') }">
              <label class="form-label string required" for="video_name">
                Name
                <abbr title="required">*</abbr>
              </label>
              <div class="form-input-main">
                <input v-model="name" autofocus="autofocus" class="string required" type="text" id="video_name" required />
                <small v-if="hasErrors('name')" small class="form-error">{{errors.name[0]}}</small>
              </div>
            </div>

            <div class="form-input string required video_description" v-bind:class="{ error: hasErrors('description') }">
              <label class="form-label string required" for="video_description">
                Description
                <abbr title="required">*</abbr>
              </label>
              <div class="form-input-main">
                <textarea v-model="description" rows="4" class="string required" id="video_description" required />
                <small v-if="hasErrors('description')" small class="form-error">{{errors.description[0]}}</small>
              </div>
            </div>

            <template>
              <div v-if="isSecure && stripeConfirmed" class="form-input string video_price" v-bind:class="{ error: hasErrors('price') }">
                <label class="form-label string" for="video_price">
                  Premium Price
                </label>
                <div class="form-input-main">
                  <small><em>(additional cost on top of a subscription)</em></small>
                  <input v-model="price" autofocus="autofocus" class="string" type="text" id="video_price" required placeholder="4.95" :disabled="purchases" />
                  <small v-if="hasErrors('price')" small class="form-error">{{errors.price[0]}}</small>
                  <small class="form-note" v-if="purchases">Because there are purchases/views, price can no longer be changed. </small>
                  <div v-if="!purchases">
                    <small>The final amount deposited in your account will be reduced by:</small>
                    <ol>
                      <li><small>- Stripe's fee of 2.9% + $0.30</small></li>
                      <li><small>- Pennant's fee (varies based on your plan)</small></li>
                    </ol>
                    <!-- <p>For example, if the customer price is $4.95, the Stripe fee will be $0.44, and -->
                    <!-- the Pennant fee is always $1.00, resulting in $3.51 deposited to your account.</p> -->
                  </div>
                </div>
              </div>
              <div v-else class="form-input">
                <label class="form-label string required" for="video_price">
                  Premium
                </label>
                <div class="form-input-main">
                  <alert v-if="!isSecure" type="warning">Only secure videos can have a price set. A video can only be
                    marked secure when it is first uploaded. If this video requires a price,
                    you can delete this record with the button at the bottom of the page, and then
                    re-upload it after choosing the 'secure' option.</alert>
                  <alert v-if="isSecure && !stripeConfirmed" type="warning" :actions="[ { name: 'Setup Stripe account', href: '/account'  } ]">You must finish configuring your Stripe account before setting a price.</alert>
                </div>
              </div>
            </template>

            <div class="form-input series">
              <label class="form-label string" for="series">
                Series
              </label>
              <div v-if="series.length > 0" class="form-input-main">
                <select v-model="seriesId">
                  <option value=""></option>
                  <option v-for="s in series" :value="s.id">{{s.name}}</option>
                </select>
                <small class="form-note">
                  Select a Series to bundle this video with others. A Series of videos can be sold as one unit.
                </small>
              </div>
              <div v-else class="form-input-main">
                <em>
                  If you want to sell access to this video as part of a series, you'll need to
                  first <a href="/series/new">create a new series</a>
                </em>
              </div>
            </div>

            <div class="form-input categories">
              <label class="form-label string" for="categories">
                Categories
              </label>
              <div class="form-input-main">
                <vue-tags-input
                  v-model="tag"
                  :tags="tags"
                  placeholder="Add Category"
                  :autocomplete-items="autocompleteItems"
                  :add-only-from-autocomplete="true"
                  :autocomplete-min-length="0"
                  @tags-changed="newTags => tags = newTags" />
              </div>
            </div>

            <div class="form-input string required image_file" v-bind:class="{ error: !imageValid || hasErrors('image_url') }">
              <label class="form-label string required">
                Image
                <abbr title="required">*</abbr>
              </label>
              <div class="form-input-main">
                <div v-if="imageUrl.length > 0" class="mb-3">
                  <img :src="imageUrl" class="rounded-md">
                </div>
                <dashboard-modal :uppy="uppy" :plugins="['ImageEditor']" :open="uploaderModalOpen" :props="dashboardModalOptions" />
                <btn :icon="false" variant="secondary" tag="button" accesskey="a" @click.native.prevent="openUploader">Choose Image</btn>
                <small v-if="hasErrors('image_url')" small class="form-error">{{errors.image_url[0]}}</small>
              </div>
            </div>

            <div class="form-input shopify_custom_collection">
              <label class="form-label string">
                Shopify Collection
              </label>
              <div class="form-input-main">
                <div v-if="shopifyCollections.length > 0">
                  <select v-model="shopifyCollection">
                    <option value="0">No Collection</option>
                    <option v-for="c in shopifyCollections" :key="c.id" :value="c.id">
                      {{ c.title }}
                    </option>
                  </select>
                </div>
                <alert v-else>No collections set up yet.</alert>
              </div>
            </div>

            <div class="form-input required video_available" v-bind:class="{ error: hasErrors('date_available') }">
              <label class="form-label string required" for="video_available">
                Available Date (Local Time)
                <abbr title="required">*</abbr>
              </label>
              <div class="form-input-main">
                <datetime v-model="dateAvailable" type="datetime" :minute-step="15" input-class="sm" ></datetime>
                <small v-if="hasErrors('date_available')" small class="form-error">{{errors.date_available[0]}}</small>
              </div>
            </div>

            <div v-if="tenantLogoPresent" class="form-input boolean required video_published switch">
              <label class="form-label">Active?</label>
              <div class="form-input-main">
                <div class="form-checkbox">
                  <div class="form-toggle">
                    <input v-model="published" id="switchPublished" type="checkbox" name="switchPublished">
                    <label for="switchPublished"/>
                  </div>
                </div>
                <small v-if="hasErrors('published')" small class="form-error">{{errors.published[0]}}</small>
                <small class="form-hint">Enable to publish this video.</small>
              </div>
            </div>
            <div v-else class="form-input video_published">
              <label class="form-label">Published</label>
              <div class="form-input-main">
                <alert type="warning" :actions="[ { name: 'Upload channel logo', href: '/account'  } ]">Videos can't be published until a channel logo is uploaded.</alert>
              </div>
            </div>
          </form-inputs>

        </div>

      </div>

      <div v-if="errors && errors.delete" style="color: red">{{errors.delete}}</div>
     
      <form-actions>
        <btn v-if="purchases" variant="secondary" class="disabled:pointer-events-none" icon="circle-exclamation" tag="button" disabled="disabled">Video has views/purchases, cannot delete</btn>
        <btn v-if="featured" variant="secondary" class="disabled:pointer-events-none" icon="circle-exclamation" tag="button" disabled="disabled">Video featured on homepage, cannot delete</btn>
        <btn v-if="!purchases && !featured" icon="xmark" icon-placement="before" variant="secondary" tag="button" accesskey="d" @click.native.prevent="deleteVideo" :disabled="isSubmitting">Delete</btn>
        <btn type="submit" :icon="false" tag="button" accesskey="s" @click.native.prevent="saveVideo" :disabled="isSubmitting">Save Video</btn>
      </form-actions>

    </form>
  </div>
</template>

<script>
import { DashboardModal } from '@uppy/vue'
import VueTagsInput from '@johmun/vue-tags-input'
import { Datetime } from 'vue-datetime'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/image-editor/dist/style.css'
import 'vue-datetime/dist/vue-datetime.css'

import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import ImageEditor from '@uppy/image-editor'

import videojs from 'video.js'

export default {
  props: {
    initCategories: Array,
    initCategoriesSelected: Array,
    initId: String,
    initName: String,
    initDescription: String,
    initIsSecure: Boolean,
    initImageUrl: String,
    initDateAvailable: String,
    initPublished: Boolean,
    initVideoUrl: String,
    initPreviewUrl: String,
    initPrice: String,
    initPurchases: Boolean,
    initSeriesId: String,
    initShopifyCollection: Number,
    initShopifyCollections: Array,
    initVideoStatus: String,
    tenantLogoPresent: Boolean,
    series: Array,
    stripeConfirmed: Boolean,
    featured: Boolean,
  },
  data() {
    return {
      id: this.initId,
      name: this.initName || '',
      description: this.initDescription || '',
      isSecure: this.initIsSecure,
      imageUrl: this.initImageUrl || '',
      dateAvailable: this.initDateAvailable || '',
      published: this.initPublished,
      videoUrl: this.initVideoUrl || '',
      videoStatus: this.initVideoStatus || '',
      previewStart: '',
      previewEnd: '',
      previewProcessing: false,
      previewUrl: this.initPreviewUrl || '',
      tag: '',
      tags: this.initCategoriesSelected,
      lastSavedTags: this.initCategoriesSelected,
      autocompleteItems: this.initCategories,
      errors: null,
      showFlash: false,
      isSubmitting: false,
      imageValid: true,
      uploaderModalOpen: false,
      price: this.initPrice || '',
      purchases: this.initPurchases || false,
      seriesId: this.initSeriesId || '',
      shopifyCollection: this.initShopifyCollection,
      shopifyCollections: this.initShopifyCollections,
      dashboardModalOptions: {
        onRequestCloseModal: this.closeUploader,
        closeAfterFinish: true,
        showLinkToFileUploadResult: false,
        note: "Image should be 16:9 ratio. Click the pencil icon to crop if necessary.",
        locale: {
          strings: {
            dropPaste: 'Drop image file here, paste or %{browse}',
          }
        }
      },
    }
  },
  components: {
    DashboardModal,
    VueTagsInput,
    Datetime
  },
  computed: {
    uppy: function () {
      return new Uppy({
        allowMultipleUploads: false,
        restrictions: {
          allowedFileTypes: ['image/*'],
          minNumberOfFiles: 1,
          maxNumberOfFiles: 1,
          maxTotalFileSize: 10485760
        }
      }).use(ImageEditor, {
        id: 'ImageEditor',
        quality: 0.8,
        actions: {
          revert: false,
          rotate: false,
          flip: false,
          zoomIn: true,
          zoomOut: true,
          cropSquare: false,
          cropWidescreen: false,
          cropWidescreenVertical: false
        },
        cropperOptions: {
          viewMode: 1,
          background: false,
          autoCropArea: 1,
          responsive: true,
          aspectRatio: 16/9,
        },
      }).use(AwsS3Multipart, {
        companionUrl: '/',
      }).on('upload-success', (file, response) => {
        this.imageUrl = response.uploadURL
      })
    },
    preparingStatus: function() {
      return (this.videoStatus == 'preparing')
    }
  },
  beforeDestroy() {
    this.uppy.close()
  },
  mounted() {
    console.log(`/videos/${this.id}/replacement`)
    if (!this.preparingStatus && this.videoUrl) {
      videojs(this.$refs.mainVideojs)
      if (this.previewUrl) {
        videojs(this.$refs.previewVideojs)
      }
    }
    console.log(this.shopifyCollection)
  },
  methods: {
    openUploader() {
      this.uploaderModalOpen = true
    },
    closeUploader() {
      this.uploaderModalOpen = false
    },
    // output: [
    //           { category_id: 1 },        // <-- added new
    //           { id: 6, _destroy: '1' },  // <-- delete
    //         ]
    getCatVideoAttrs() {
      // this.initCategoriesSelected is the array of exisiting categories
      //  [ {text: 'cat1', id: 3, category_id: 1}, {text: 'cat2', id: 4, category_id: 2} ]
      // this.initCategories is the array of all categories
      //  [ {text: 'cat1', category_id: 1}, {text: 'cat2', category_id: 2}, {text: 'cat3', category_id: 3} ]
      
      let arr = []
      const tagIds = this.tags.map(e => { return e.category_id })
      const oldIds = this.lastSavedTags.map(e => { return e.category_id })

      // deletions
      this.lastSavedTags.map(e => {
        if (tagIds.indexOf(e.category_id) == -1) {
          arr.push( { id: e.id, _destroy: '1'} )
        }
      })

      // additions
      this.tags.map(e => {
        if (oldIds.indexOf(e.category_id) == -1) {
          arr.push( { category_id: e.category_id } )
        }
      })

      // locally save current tag state for future edits
      this.lastSavedTags = this.tags
      
      return arr
    },

    saveVideo() {
      this.isSubmitting = true

      let catVideoAttrs = this.getCatVideoAttrs()
      
      let params = {
        format: "json",
        video: {
          name: this.name,
          description: this.description,
          image_url: this.imageUrl,
          published: this.published,
          date_available: this.dateAvailable,
          preview_start: this.previewStart,
          preview_end: this.previewEnd,
          price_cents: (this.price.trim().length == 0) ? 0 : this.price * 100,
          series_id: this.seriesId,
          shopify_custom_collection_id: (this.shopifyCollection == 0) ? null : this.shopifyCollection
        }
      }

      if (catVideoAttrs.length > 0) {
        params['video']['category_videos_attributes'] = catVideoAttrs
      }

      let requestParams = {
        method: 'put',
        url: `/videos/${this.id}`,
        data: params,        
        headers: {
          'X-CSRF-Token': document.querySelectorAll(`meta[name="csrf-token"]`)[0].getAttribute('content')
        }
      }

      this.$http(requestParams)
        .then((response) => {
          if (response.data.success) {
            if (this.id !== response.data.video_id) {
              window.location = "/videos/" + response.data.video_id
            }

            this.errors = null
            this.showFlash = true
            window.scrollTo(0,0)
            if (this.previewStart) {
              this.previewProcessing = true
            }
          } else {
            this.showFlash = false
            this.errors = response.data.errors
            this.$refs.errorSummary.scrollIntoView()
          }
          this.isSubmitting = false
        }).catch((e) => {
          console.log(e)
        })
    },
    hasErrors(field) {
      return (this.errors && this.errors[field]);
    },
    deletePreview() {
      if (confirm("Are you sure?")) {
        this.isSubmitting = true

        let params = {
          format: 'json',
          video: {
            id: this.id,
          }
        }

        let requestParams = {
          method: 'delete',
          url: `/videos/${this.id}/preview`,
          data: params,
          headers: {
            'X-CSRF-Token': document.querySelectorAll(`meta[name="csrf-token"]`)[0].getAttribute('content')
          }
        }

        this.$http(requestParams)
          .then((response) => {
            if (response.data.success) {
              // was running into some weird issues with the preview video player persisting after
              // vue removed its containing element. Refreshing the page fixes it.
              window.location.href = '/videos/' + this.id
            } else {
              this.errors = response.data.errors
            }
            this.isSubmitting = false
        }).catch((e) => {
          console.log(e)
        })
      }
    },
    deleteVideo() {
      if (confirm("Are you sure?")) {
        
        this.isSubmitting = true
        
        let params = {
          format: 'json',
          video: {
            id: this.id,
          }
        }
        
        let requestParams = {
          method: 'delete',
          url: `/videos/${this.id}`,
          data: params,
          headers: {
            'X-CSRF-Token': document.querySelectorAll(`meta[name="csrf-token"]`)[0].getAttribute('content')
          }
        }
        
        this.$http(requestParams)
          .then((response) => {
            if (response.data.success) {
              this.errors = null
              window.location.href = `/videos`
            } else {
              this.errors = response.data.errors
              this.isSubmitting = false
            }
        }).catch((e) => {
          console.log(e)
        })
      }
      
    },
    replaceLink() {
      console.log("replace!");
    },

  },
}  
</script>
