<template>
  <div>
    <div class="group aspect-w-16 aspect-h-9 cursor-pointer" @click="$emit('click')">
      <div class="flex items-center justify-center border-3 border-dashed border-neutral-200 group-hover:border-neutral-300 rounded-lg">
        <div class="p-4">
          <font-awesome-icon class="block mx-auto mb-3 h-8 w-8 text-neutral-400" :icon="['fas', 'plus']" />
          <div v-if="description" class="font-medium text-neutral-500 text-center">{{ description }}</div>
        </div>
      </div>
    </div>
    <p class="mt-2 block text-sm font-medium text-neutral-900 text-center truncate">
      <slot>&nbsp;</slot>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    description: String
  }
}
</script>
