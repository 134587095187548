<template>
  <div class="rounded-lg px-6 py-4 min-w-[300px] w-full" :class="bgColor">
    <div class="flex items-center">
      <font-awesome-icon class="h-5 w-5 mr-2" :icon="['fas', titleIcon]" />
      <div class="text-lg font-medium">{{title}}</div>
    </div>
    <div class="flex pr-2 pb-2 mt-5 justify-between border-b" :class="{ 'border-[#E4DB00]': isHighlighted }">
      <div class="text-2xl font-bold">{{amount}}</div>
      <div v-if="percent != 0" class="pt-1" :class="trendColor">
        <font-awesome-icon class="h-4 w-4 mr-1" :icon="['far', trendIcon]" />
        <span>{{percentDisplay}}</span>
      </div>
    </div>
    <div class="flex py-2 items-center opacity-50">
      <div class="text-lg mr-2">{{amountPrev}}</div>
      <div>previous period</div>
    </div>
    <div class="h-[100px]">
      <apexchart height="100%"
                 width="100%"
                 type="area"
                 :series="seriesData"
                 :options="chartOptions"></apexchart>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    amount: String,
    amountPrev: String,
    dataPoints: Array,
    isHighlighted: Boolean,
    isMoney: Boolean,
    percent: Number,
    title: String,
    titleIcon: String,
  },
  data() {
    return {
    }
  },
  components: {
  },
  computed: {
    trendIcon() {
      return (this.percent > 0) ? 'arrow-trend-up' : 'arrow-trend-down'
    },
    trendColor() {
      return (this.percent > 0) ? 'text-green-600' : 'text-red-600'
    },
    bgColor() {
      return (this.isHighlighted) ? 'bg-[#FEF400]' : 'bg-white'
    },
    percentDisplay() {
      return Math.abs(this.percent) + "%"
    },
    seriesData() {
      return [{ data: this.dataPoints }]
    },
    // https://apexcharts.com/docs/options/
    chartOptions() {
      return {
        stroke: {
          colors: ['#000000']
        },
        fill: {
          type: "gradient",
          colors: ['#000000'],
          gradient: {
            opacityFrom: 0.4,
            opacityTo: 0,
          }
        },
        tooltip: {
          fixed: {
            enabled: false
          },
          x: {
            show: false
          },
          y: {
            formatter: (val) => {
              if (!this.isMoney) {
                return val
              }
              
              val = val.toString()
              let parts = val.split('.')
              let needsZero = (parts.length == 2) && (parts[1].length == 1)
              if (needsZero) {
                val += 0
              }

              return '$' + val
            },
            title: {
              formatter: function (seriesName) {
                return ''
              }
            }
          },
          marker: {
            show: false
          }
        },
        chart: {
          sparkline: {
            enabled: true
          },
        },
      }
    }
  },
  methods: {
  },
}
</script>

