<template>
  <div>
    <alert v-if="showFlash" class="mb-4" type="success" dismissible>Series saved.</alert>

    <form class="simple_form form edit_series" id="edit_series">

      <form-heading title="Series Details" description="Configure the appearance of this series" />

      <p v-if="hasErrors('base')" style="color: #cc4b37;">{{errors.base[0]}}</p>

      <form-inputs>

        <div class="form-input string required series_name" v-bind:class="{ error: hasErrors('name') }">
          <label class="form-label string required" for="series_name">
            Name
            <abbr title="required">*</abbr>
          </label>
          <div class="form-input-main">
            <input v-model="name" autofocus="autofocus" class="string required" type="text" id="series_name" required />
            <small v-if="hasErrors('name')" small class="form-error">{{errors.name[0]}}</small>
          </div>
        </div>

        <div class="form-input string required series_description" v-bind:class="{ error: hasErrors('description') }">
          <label class="form-label string required" for="series_description">
            Description
            <abbr title="required">*</abbr>
          </label>
          <div class="form-input-main">
            <textarea v-model="description" rows="4" class="string required" id="series_description" required />
            <small v-if="hasErrors('description')" small class="form-error">{{errors.description[0]}}</small>
          </div>
        </div>

        <div class="form-input string required series_caption" v-bind:class="{ error: hasErrors('caption') }">
          <label class="form-label string required" for="series_caption">
            Caption
            <abbr title="required">*</abbr>
          </label>
          <div class="form-input-main">
            <input v-model="caption" class="string required" type="text" id="series_caption" required />
            <small v-if="hasErrors('caption')" small class="form-error">{{errors.caption[0]}}</small>
          </div>
        </div>

        <div class="form-input string series_price" v-bind:class="{ error: hasErrors('price') }">
          <label class="form-label string required" for="series_price">
            Price
            <abbr title="required">*</abbr>
          </label>
          <div class="form-input-main">
            <input v-model="price" autofocus="autofocus" class="xs string" type="text"
            id="series_price" required placeholder="9.95" />
            <small v-if="hasErrors('price')" small class="form-error">{{errors.price[0]}}</small>
            <div class="form-hint prose">
              <p>This is what customers will pay for access to all the videos in this series.</p>
              <!-- TODO asses for DRY -->
              <p>The final amount deposited in your account will be reduced by:</p>
              <ol class="ml-2">
                <li>Stripe's fee of 2.9% + $0.30</li>
                <li>Pennant's fee of $1.00</li>
              </ol>
              <p>For example, if the customer price is $9.95, the Stripe fee will be $0.59, and
              the Pennant fee is always $1.00, resulting in $8.36 deposited to your account.</p>
            </div>
          </div>
        </div>

        <div class="form-input string required image_file" v-bind:class="{ error: !imageValid || hasErrors('image_url')}">
          <label class="form-label string required">
            Image
            <abbr title="required">*</abbr>
          </label>
          <div class="form-input-main">
            <div v-if="imageUrl.length > 0" class="mb-3">
              <img :src="imageUrl" class="rounded-md">
            </div>
            <dashboard-modal :uppy="uppy" :plugins="['ImageEditor']" :open="uploaderModalOpen" :props="dashboardModalOptions" />
            <btn variant="secondary" tag="button" :icon="false" accesskey="a" @click.native.prevent="openUploader">Choose Image</btn>
            <small v-if="hasErrors('image_url')" small class="form-error">{{errors.image_url[0]}}</small>
          </div>
        </div>

        <div class="form-input required boolean series_is_active">
          <label class="form-label">Active?</label>
          <div class="form-input-main">
            <div class="form-checkbox">
              <div class="form-toggle">
                <input v-model="isActive" id="series_is_active" type="checkbox" name="series_is_active">
                <label for="series_is_active" aria-hidden="true"></label>
              </div>
              <label class="form-label">Active?</label>
            </div>
            <small v-if="hasErrors('is_active')" small class="form-error">{{errors.is_active[0]}}</small>
            <small class="form-hint">Enable to publish this series.</small>
          </div>
        </div>

      </form-inputs>

      <form-actions>
        <btn v-if="purchases || featured || highlighted" variant="secondary" class="disabled:pointer-events-none" icon="xmark" tag="button"
                                                                             disabled="disabled">
          {{noDeleteError()}}
        </btn>
        <btn v-if="isUpdate && !purchases && !featured && !highlighted" variant="secondary" icon="xmark" icon-placement="before" tag="button" accesskey="d" @click.native.prevent="deleteSeries" :disabled="isSubmitting">Delete</btn>
        <btn type="submit" :icon="false" tag="button" accesskey="s" @click.native.prevent="saveSeries" :disabled="isSubmitting">Save Series</btn>
      </form-actions>

    </form>
  </div>
</template>

<script>
import { DashboardModal } from '@uppy/vue'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/image-editor/dist/style.css'

import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import ImageEditor from '@uppy/image-editor'

export default {
  props: {
    initId: String,
    initName: String,
    initPrice: String,
    initDescription: String,
    initCaption: String,
    initIsActive: Boolean,
    initPurchases: Boolean,
    initImageUrl: String,
    featured: Boolean,
    highlighted: Boolean,
  },
  data() {
    return {
      id: this.initId,
      name: this.initName || '',
      description: this.initDescription || '',
      imageUrl: this.initImageUrl || '',
      caption: this.initCaption || '',
      isActive: (this.initIsActive == 'true'),
      isUpdate: false,
      price: this.initPrice || '',
      purchases: this.initPurchases || false,
      errors: null,
      showFlash: false,
      isSubmitting: false,
      imageValid: true,
      uploaderModalOpen: false,
      dashboardModalOptions: {
        onRequestCloseModal: this.closeUploader,
        closeAfterFinish: true,
        showLinkToFileUploadResult: false,
        note: "Image should be 16:9 ratio. Click the pencil icon to crop if necessary.",
        locale: {
          strings: {
            dropPaste: 'Drop image file here, paste or %{browse}',
          }
        }
      }
    }
  },
  components: {
    DashboardModal,
  },
  computed: {
    uppy: function () {
      return new Uppy({
        allowMultipleUploads: false,
        restrictions: {
          allowedFileTypes: ['image/*'],
          minNumberOfFiles: 1,
          maxNumberOfFiles: 1,
          maxTotalFileSize: 10485760
        }
      }).use(ImageEditor, {
        id: 'ImageEditor',
        quality: 0.8,
        actions: {
          revert: false,
          rotate: false,
          flip: false,
          zoomIn: true,
          zoomOut: true,
          cropSquare: false,
          cropWidescreen: false,
          cropWidescreenVertical: false
        },
        cropperOptions: {
          viewMode: 1,
          background: false,
          autoCropArea: 1,
          responsive: true,
          aspectRatio: 16/9,
        },
      }).use(AwsS3Multipart, {
        companionUrl: '/',
      }).on('upload-success', (file, response) => {
        // console.log("file upload success")
        // console.log("file: ", JSON.stringify(file))
        // console.log("response: ", JSON.stringify(response))
        this.imageUrl = response.uploadURL
      })
    },
  },
  beforeDestroy() {
    this.uppy.close()
  },
  mounted() {
    this.isUpdate = (this.id != undefined)
  },
  methods: {
    noDeleteError() {
      if (this.purchases) {
        return "Series has active purchases, cannot delete"
      } else if (this.featured && !this.highlighted) {
        return "Series featured on homepage, cannot delete"
      } else if (!this.featured && this.highlighted) {
        return "Series highlighted on homepage, cannot delete"
      } else {
        return "Series featured and highlighted on homepage, cannot delete"
      }
    },
    openUploader() {
      this.uploaderModalOpen = true
    },
    closeUploader() {
      this.uploaderModalOpen = false
    },
    saveSeries() {
      this.isSubmitting = true

      let params = {
        series: {
          name: this.name,
          description: this.description,
          caption: this.caption,
          is_active: this.isActive,
          image_url: this.imageUrl,
          price_cents: this.price ? this.price * 100 : null,
        }
      }

      let requestParams = {
        method: (this.isUpdate) ? 'put' : 'post',
        url: (this.isUpdate) ? `/series/${this.id}` : '/series',
        data: params,
        headers: {
          'X-CSRF-Token': document.querySelectorAll(`meta[name="csrf-token"]`)[0].getAttribute('content')
        }
      }

      this.$http(requestParams)
        .then((response) => {
          if (response.data.success) {
            if (this.isUpdate) {
              this.errors = null
              this.showFlash = true
              window.scrollTo(0,0)
            } else {
              window.location.href = `/series/${response.data.series_id}`
            }
          } else {
            this.showFlash = false
            this.errors = response.data.errors
          }
          this.isSubmitting = false
        }).catch((e) => {
          console.log(e)
        })
    },
    hasErrors(field) {
      return (this.errors && this.errors[field]);
    },
    deleteSeries() {

      if (confirm("Are you sure?")) {

        this.isSubmitting = true

        let params = {
          series: {
            id: this.id,
          }
        }

        let requestParams = {
          method: 'delete',
          url: `/series/${this.id}`,
          data: params,
          headers: {
            'X-CSRF-Token': document.querySelectorAll(`meta[name="csrf-token"]`)[0].getAttribute('content')
          }
        }
 
        this.$http(requestParams)
          .then((response) => {
            if (response.data.success) {
              this.errors = null
              window.location.href = `/series`
            } else {
              this.errors = response.data.errors
              this.isSubmitting = false
            }
        }).catch((e) => {
          console.log(e)
        })

      }

    }
  },
}
</script>
