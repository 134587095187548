<template>
  <div>
    <transition name="transition-fade">
      <alert v-if="showFlash" class="fixed bottom-0 z-40 right-0 mb-4 mr-4" type="success" :inline="true">Videos saved</alert>
    </transition>

    <form class="simple_form form edit_series_videos" id="edit_series_videos">

      <div ref="errorSummary" class="mb-3">
        <alert v-if="this.errors" type="error">
          {{ errors }}
        </alert>
      </div>
      
      <form-heading title="Videos" description="Add published videos to this series. Drag to reorder." />

      <div class="mt-6">
        <draggable
          class="grid grid-cols-2 gap-6 lg:grid-cols-3 xl:grid-cols-4"
          tag="ul"
          role="list"
          draggable=".item"
          v-model="seriesVideos"
          @end="save">
          <template v-if="seriesVideos.length">
            <item
              v-for="video in this.seriesVideos"
              :key="video.id"
              class="item cursor-move active:opacity-75"
              tag="li"
              :image-url="video.image_url"
              btn-icon="close"
              @btn-click="() => removeSeriesVideo(video.id)">
              {{ video.name }}
            </item>
          </template>
          <item-empty
            slot="footer"
            v-if="videosNotInSeries.length"
            description="Add video"
            @click="showAvailableVideos = true"
          />
      </draggable>
        <slide-over
          title="Videos available"
          :active.sync="showAvailableVideos"
          :overlay-transparent="true">
          <ul v-if="this.videosNotInSeries.length" role="list"
            class="grid grid-cols-2 sm:grid-cols-3 gap-6">
            <item
              v-for="video in this.videosNotInSeries"
              :key="video.id"
              class="relative"
              tag="li"
              :image-url="video.image_url"
              btn-icon="plus"
              @btn-click="() => addSeriesVideo(video.id)">
              {{ video.name }}
            </item>
          </ul>
          <alert v-else>All videos are already in this series.</alert>
        </slide-over>
      </div>
      
    </form>
    
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  props: {
    id: String,
    videos: Array,
    initSeriesVideos: Array,
  },
  data() {
    return {
      seriesVideos: this.initSeriesVideos,
      showAvailableVideos: false,
      showFlash: false,
      errors: null,
    }
  },
  components: {
    draggable,
  },
  computed: {
    videosNotInSeries() {
      let seriesVideoIds = this.seriesVideoIds()
      return this.videos.filter(video => !seriesVideoIds.includes(video.id))
    }
  },
  methods: {
    addSeriesVideo(videoId) {
      this.seriesVideos.push(this.videos.find(video => video.id === videoId))
      this.save()
    },
    removeSeriesVideo(videoId) {
      this.seriesVideos = this.seriesVideos.filter(video => video.id !== videoId)
      this.save()
    },
    seriesVideoIds() {
      return this.seriesVideos.map(video => video.id)
    },
    save() {
      let params = {
        format: "json",
        series_id: this.id,
        series_video_ids: this.seriesVideoIds().join(", "),
      }

      let requestParams = {
        method: 'put',
        url: `/series/${this.id}/update_videos`,
        data: params,
        headers: {
          'X-CSRF-Token': document.querySelectorAll(`meta[name="csrf-token"]`)[0].getAttribute('content')
        }
      }

      this.$http(requestParams)
        .then((response) => {
          if (response.data.success) {
            this.errors = null
            this.showFlash = true
            clearTimeout(this.cancelFlash)
            this.cancelFlash = setTimeout(() => this.showFlash = false, 2000)
          } else {
            this.showFlash = false
            this.errors = response.data.errors
            this.$refs.errorSummary.scrollIntoView()
          }
        }).catch((e) => {
          console.log(e)
        })
      
    },
  },
}  
</script>  
