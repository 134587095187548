<template>
  <div>
    <alert v-if="showFlash" class="mb-4" type="success">Subscription saved.</alert>

    <alert v-if="showGeneralError" class="mb-4" type="error" dismissible>There was a problem saving your settings. Please try again momentarily.</alert>

    <form class="simple_form form edit_subscription" id="edit_subscription">

      <form-heading title="Subscription Configuration" description="Manage subscriptions for your channel" />

      <p v-if="hasErrors('base')" style="color: #cc4b37;">{{errors.base[0]}}</p>

      <form-inputs>
        
        <div class="form-input boolean required">
          <label class="form-label">Current Price</label>
          <div class="form-input-main">
             <p class="form-note">{{nonZeroPrice ? `$${priceCurrent}` : 'Free'}}</p>
          </div>
        </div>
        
        <div class="form-input string" v-bind:class="{ error: hasErrors('price') }">
          <label class="form-label string required" for="subscription_price">
            Price
            <abbr title="required">*</abbr>
          </label>
          <div class="form-input-main">            
            <input v-model="price" autofocus="autofocus" class="string" type="text" id="subscription_price" required placeholder="0" :disabled="!isStripeSetup" />
            <small>per month</small>
            <small v-if="hasErrors('price')" small class="form-error">{{errors.price[0]}}</small>
          </div>
        </div>

        <div class="form-input boolean required">
          <label class="form-label">Fees</label>
          <div class="form-input-main">
            <div v-if="nonZeroPrice">
              <table>
                <tr>
                  <td class="pr-8"><i>Pennant:</i></td>
                  <td>-$1.00</td>
                </tr>
                <tr style="border-bottom: 1px dashed #ccc;">
                  <td><i>Stripe:</i></td>
                  <td>-${{stripeFee}}</td>
                </tr>
                <tr>
                  <td>Net:</td>
                  <td>${{netPrice}}</td>
                </tr>
              </table>
            </div>
            <div v-else>
              <p>-</p>
            </div>
          </div>
        </div>
        
      </form-inputs>

      <form-actions>
        <btn v-if="!isStripeSetup || isSubmitting" variant="secondary" :icon="false" tag="button" disabled="disabled" class="disabled:pointer-events-none">Save</btn>
        <btn v-if="isStripeSetup" type="submit" :icon="false" tag="button" accesskey="s" @click.native.prevent="save" :disabled="isSubmitting">Save</btn>
      </form-actions>
      
    </form>
  </div>
</template>

<script>
export default {
  props: {
    initIsStripeSetup: Boolean,
    initPrice: String,
    initStripeFee: String,
    initNetPrice: String,
  },
  data() {
    return {
      isStripeSetup: this.initIsStripeSetup,
      price: this.initPrice || '',
      priceCurrent: this.initPrice || '',
      stripeFee: this.initStripeFee || '',
      netPrice: this.initNetPrice || '',
      errors: null,
      showFlash: false,
      showGeneralError: false,
      isSubmitting: false,
    }
  },
  mounted() {
  },
  computed: {
    nonZeroPrice() {
      return (this.priceCurrent.length > 0 && this.priceCurrent != '0')
    }
  },
  methods: {
    hasErrors(field) {
      return (this.errors && this.errors[field]);
    },
    save() {
      this.isSubmitting = true
      this.showFlash = false

      let params = {
        subscription: {
          price: this.price
        }
      }

      let requestParams = {
        method: 'post',
        url: '/subscriptions/update',
        data: params,
        headers: {
          'X-CSRF-Token': document.querySelectorAll(`meta[name="csrf-token"]`)[0].getAttribute('content')
        }
      }

      this.$http(requestParams)
        .then((response) => {
          if (response.data.success) {
            this.errors = null
            this.showGeneralError = false
            this.showFlash = true
            this.priceCurrent = this.price
            this.stripeFee = response.data.stripe_fee
            this.netPrice = response.data.net_price
            window.scrollTo(0,0)
          } else {
            this.showFlash = false
            this.showGeneralError = !!response.data.general_error
            this.errors = response.data.errors
            if (response.data.previous_price) {
              this.price = response.data.previous_price
            } else {
              this.subscriptionsOnly = false
            }
          }
          this.isSubmitting = false
        }).catch((e) => {
          console.log(e)
        })      
    },
  },
}
</script>
