<template>
  <div class="pb-6 sm:pb-5 border-b border-b-neutral-200">
    <h3 v-if="title">{{ title }}</h3>
    <p v-if="$slots.default || description" class="mt-1 max-w-2xl text-neutral-500">
      <slot>{{ description }}</slot>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    description: String
  }
}
</script>
