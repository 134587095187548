<template>

  <form class="simple_form form new_video" id="new_video">

    <alert v-if="hasErrors('base')" class="mb-4" type="error" dismissible>{{ errors.base[0] }}</alert>

    <form-heading title="Video Details" description="" />
  
    <form-inputs>
      
      <div v-if="!isReplacementVideo" class="form-input string required video_name" v-bind:class="{ error: hasErrors('name') }">
        <label class="form-label string required" for="video_name">
          Name
          <abbr title="required">*</abbr>
        </label>
        <div class="form-input-main">
          <input v-model="name" autofocus="autofocus" class="string required" type="text" id="video_name" required />
          <small v-if="hasErrors('name')" small class="form-error">{{errors.name[0]}}</small>
        </div>
      </div>

      <div class="form-input string required video_file" v-bind:class="{ error: hasErrors('video_filename') }">
        <label class="form-label string required" for="video_filename">
          Video File
          <abbr title="required">*</abbr>
        </label>
        <div class="form-input-main">
          <mux-uploader-drop mux-uploader="uploader">
            <div slot="heading">
              <svg aria-hidden="true" focusable="false" class="uppy-c-icon uppy-DragDrop-arrow" width="16" height="16" viewBox="0 0 16 16"><path fill="#d4d4d4" d="M11 10V0H5v10H2l6 6 6-6h-3zm0 0" fillRule="evenodd"></path></svg>
            </div>
            <div slot="separator"></div>
            <mux-uploader no-drop id="uploader" ref="mux-uploader" :endpoint="muxVideoUploadUrl">
              <p slot="file-select" class="file-select">Drop video file here or <span>browse</span></p>
            </mux-uploader>
          </mux-uploader-drop>
          <input v-model="video_filename" class="string required" type="text" id="video_filename" placeholder="video filename will appear here" required disabled />
          <small v-if="hasErrors('video_filename')" small class="form-error">{{errors.video_filename[0]}}</small>
        </div>
      </div>

      <div class="form-input string required captions_file">
        <label class="form-label string" for="captions_filename">
          Captions File (optional)
        </label>
        <div class="form-input-main">
          <drag-drop :uppy="uppyCaptions" :props="dragDropCaptionOptions" />
          <div style="height: 8px;"/>
          <progress-bar :uppy="uppyCaptions" />
          <input v-model="captions_filename" class="string" type="text" id="captions_filename" placeholder="captions filename will appear here" disabled />
        </div>             
      </div>

    </form-inputs>

    <form-actions>
      <btn type="submit" :icon="isPennant ? 'angle-right' : false" tag="button" accesskey="s" @click.native.prevent="createVideo" :disabled="isSubmitting || videoUploading" class="disabled:opacity-40 disabled:pointer-events-none" >
        <template v-if="isPennant && !isReplacementVideo">Next Step</template>
        <template v-else>Save</template>
      </btn>
    </form-actions>    
    
  </form>
</template>

<script>
import { DragDrop, ProgressBar } from '@uppy/vue'

import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import '@uppy/progress-bar/dist/style.css'

import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'

import '@mux/mux-uploader';

export default {
  props: {
    initIsPennant: Boolean,
    initIsReplacementVideo: Boolean,
    initId: String
  },
  data() {
    return {
      isPennant: this.initIsPennant,
      isReplacementVideo: this.initIsReplacementVideo,
      id: this.initId,
      name: '',
      video_filename: '',
      captions_filename: '',
      captions_upload_url: '',
      
      muxVideoUploadUrl: '',
      muxVideoUploadId: '',
      videoUploading: false,
      videoUploadFinished: false,
      
      errors: null,
      isSubmitting: false,
      dragDropCaptionOptions: {
        note: '',
        locale: {
          strings: {
            dropHereOr: 'Drop caption file here or %{browse}'
          }
        }        
      }
    }
  },
  components: {
    DragDrop,
    ProgressBar,
  },
  computed: {
    uppyCaptions: function () {
      return new Uppy({
        allowMultipleUploads: false,
        autoProceed: true,
        restrictions: {
          allowedFileTypes: ['.vtt','.srt']
        }
      }).use(AwsS3Multipart, {
        companionUrl: '/',
      }).on('upload-success', (file, response) => {
        this.captions_upload_url = response.uploadURL
        this.onCaptionUploadSuccess()
      }).on('file-added', (file) => {
        this.captions_filename = file.name
      })
    },
  },
  beforeDestroy() {
    this.uppyCaptions.close()
  },
  mounted() {
    // console.log("id:", this.id)
    this.setMuxUploadUrl()
    
    const uploader = this.$refs["mux-uploader"]

    // file is selected
    uploader.addEventListener('file-ready', (e) => {
      this.video_filename = e.detail.name
      this.videoUploading = true
      if (!this.isReplacementVideo) {
        this.populateName()
      }
    })

    uploader.addEventListener('success', () => {
      this.videoUploading = false
      this.videoUploadFinished = true
    })

    uploader.addEventListener('uploaderror', function () {
      this.videoUploading = false
      console.log("upload error")
    })
  },
  methods: {
    createVideo() {
      this.isSubmitting = true

      let params = {
        video: {}
      }

      // only send mux_upload_id if video actually uploaded so we can trigger validation error via server
      if (!this.isReplacementVideo) {
        params['video'] = {
          name: this.name,
          mux_upload_mux_id: this.videoUploadFinished ? this.muxVideoUploadId : '',
          mux_policy_type: this.isPennant ? 'signed' : 'public'
        }
      } else {
        params['video'] = {
          has_replacement: 'true',
          mux_upload_mux_id: this.videoUploadFinished ? this.muxVideoUploadId : '',
        }
      }
      
      if (this.captions_upload_url.length > 0) {
        params['video']['uploads_attributes'] = [{ file_type: 'captions', url: this.captions_upload_url }]
      }

      let requestParams = {
        method: (this.isReplacementVideo) ? 'put' : 'post',
        url: (this.isReplacementVideo) ? `/videos/${this.id}.json` : '/videos',
        data: params,
        headers: {
          'X-CSRF-Token': document.querySelectorAll(`meta[name="csrf-token"]`)[0].getAttribute('content')
        }
      }

      // console.log("url: ", requestParams.url)

      this.$http(requestParams)
        .then((response) => {
          if (response.data.success) {
            this.clearErrors()
            window.location.href = `/videos/${response.data.video_id}`
          } else {
            this.errors = response.data.errors
            this.isSubmitting = false
          }
        }).catch((e) => {
          console.log(e)
        })
    },
    hasErrors(field) {
      return (this.errors && this.errors[field]);
    },
    clearErrors() {
      this.errors = null;
    },
    populateName() {
      // remove extention from filename
      if (this.name.length == '0') {
        let re = /(.+)\..+$/
        this.name = this.video_filename.replace(re, '$1')
      }
    },
    onCaptionUploadSuccess() {
      setTimeout(() => {
        document.querySelectorAll(".uppy-DragDrop-arrow")[1].style = "display: none;"
        document.querySelectorAll(".uppy-DragDrop-label")[0].innerHTML = "Upload complete!"
      }, "10")
    },
    // creates a MuxUpload on the backend
    setMuxUploadUrl() {
      let params = {}

      if (this.isReplacementVideo) {
        params['is_replacement'] = 'true'
      }
      
      let requestParams = {
        method: 'post',
        url: '/videos/upload',
        data: params,
        headers: {
          'X-CSRF-Token': document.querySelectorAll(`meta[name="csrf-token"]`)[0].getAttribute('content')          
        }
      }

      this.$http(requestParams)
        .then((response) => {
          if (response.data.success) {
            this.muxVideoUploadUrl = response.data.url
            this.muxVideoUploadId = response.data.mux_upload_mux_id
          } else {
            console.log("error fetching upload url")
          }
        }).catch((e) => {
          console.log(e)
        })      
    },
  },
}  
</script>
