<template>
  <transition :duration="500">
    <div v-show="active" class="fixed inset-0 z-30 overflow-hidden" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
      <div class="absolute inset-0 overflow-hidden">
        <!--
          Background overlay, show/hide based on slide-over state.

          Entering: "ease-in-out duration-500"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in-out duration-500"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <transition
          enter-active-class="transition-opacity ease-in-out duration-500"
          enter-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="transition-opacity ease-in-out duration-500"
          leave-to-class="opacity-0">
          <div v-show="overlay && active" class="absolute inset-0 bg-opacity-75" :class="[ overlayTransparent ? 'bg-transparent' : 'bg-neutral-500' ]" aria-hidden="true" @click="$emit('update:active', false)"></div>
        </transition>

        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
          <!--
            Slide-over panel, show/hide based on slide-over state.

            Entering: "transform transition ease-in-out duration-500 sm:duration-700"
              From: "translate-x-full"
              To: "translate-x-0"
            Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
              From: "translate-x-0"
              To: "translate-x-full"
          -->
          <transition
            enter-active-class="transition ease-in-out duration-500 transform"
            enter-class="translate-x-full"
            enter-to-class="translate-x-0"
            leave-active-class="transition ease-in-out duration-500 transform"
            leave-to-class="translate-x-full"
            >
            <div v-show="active" class="pointer-events-auto w-screen max-w-2xl">
              <div class="flex h-full flex-col overflow-y-scroll bg-white pt-20 md:pt-6 pb-6 shadow-xl">
                <div class="px-4 sm:px-6">
                  <div class="flex items-start justify-between">
                    <h3 v-if="title" id="slide-over-title">{{ title }}</h3>
                    <div class="ml-3 flex h-6 items-center">
                      <button type="button" class="rounded-md bg-white text-neutral-400 hover:text-neutral-500 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2" @click.prevent="$emit('update:active', false)">
                        <span class="sr-only">Close panel</span>
                        <font-awesome-icon class="align-top h-5 w-5" :icon="['fas', 'close']" />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="relative mt-6 flex-1 px-4 sm:px-6">
                  <slot />
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    title: String,
    active: Boolean,
    overlay: {
      type: Boolean,
      default: true
    },
    overlayTransparent: Boolean
  }
}
</script>
