<template>
  <div class="min-w-[300px] min-h-[578px] rounded-lg px-6 pt-4 pb-6 mt-4 xl:basis-1/3 bg-black text-white relative">
    <div class="flex items-center">
      <font-awesome-icon class="h-5 w-5 mr-2" :icon="['fas', 'bars-progress']" />
      <div class="text-lg font-medium">Usage</div>
    </div>
    <div class="h-60">
      <apexchart class="-mt-6"
                 height="320"
                 width="100%"
                 type="radialBar"
                 :series="chartPercentage"
                 :options="chartOptions"></apexchart>
    </div>
    <table class="w-full mt-12">
      <tr class="border-t border-neutral-700">
        <td class="text-neutral-400 py-4">Plan name</td>
        <td class="text-right text-lg">Main Room</td>
      </tr>
      <tr class="border-t border-neutral-700">
        <td class="text-neutral-400 py-4">Viewer Hours</td>
        <td class="text-right text-lg">{{hoursViewedPeriod}} / {{planMaxHours}}</td>
      </tr>
      <tr class="border-t border-neutral-700">
        <td class="text-neutral-400 py-4">Renews</td>
        <td class="text-right text-lg">{{renews}}</td>
      </tr>
    </table>
    <div class="absolute bottom-8 left-0 w-full flex justify-center">
      <a href="/account/plan_select" class="hover:text-black px-6 py-2.5 bg-[#FEF400] text-black font-bold rounded-full">Manage plan</a>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    hoursViewed: Number,
    hoursViewedPeriod: Number,
    planMaxHours: Number,
    renews: String
  },
  data() {
    return {
    }
  },
  components: {
  },
  computed: {
    chartPercentage() {
      return [Math.round((this.hoursViewed / this.planMaxHours) * 100)]
    },
    chartOptions() {
      return {
        fill: {
          colors: ['#FEF400'],
        },
        labels: [this.hoursViewed.toString()],
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                color: '#fff',
                fontFamily: 'inter',
                fontSize: '26px',
                fontWeight: 800,
              },
              value: {
                color: '#fff',
                fontFamily: 'inter',
                formatter: (val) => 'Hours watched'
              },
            },
            hollow: {
              size: '56%'
            },
            track: {
              background: "#404040"
            },
          },
        },
        states: {
          hover: {
            filter: {
              type: 'none',
            }
          },
          active: {
            filter: {
              type: 'none',
            }
          },          
        },        
      }
    },
  },
  methods: {
  },
}
</script>

