<template>
  <div class="min-w-[550px] rounded-lg px-6 pt-4 pb-6 mt-4 bg-white flex justify-between space-x-4">
    <div class="min-w-[360px] pr-6">
      <div class="flex items-center">
        <font-awesome-icon class="h-5 w-5 mr-2" :icon="['fas', 'arrow-right-arrow-left']" />
        <div class="text-lg font-medium">Activity</div>
      </div>
      <div class="flex pb-2 mt-5 items-center justify-between border-b">
        <div class="flex justify-between space-x-2 items-center">
          <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4" r="4" fill="#DF59F7"/>
          </svg>
          <div class="text-2xl font-bold">{{visitorCount}}</div>
          <div class="">visitors</div>
        </div>
        <div v-if="percentVisits != 0" :class="trendColor(percentVisits)">
          <font-awesome-icon class="h-4 w-4 mr-1" :icon="['far', trendIcon(percentVisits)]" />
          <span>{{percentDisplay(percentVisits)}}</span>
        </div>
      </div>
      <div class="flex pt-2 items-center justify-between">
        <div class="flex justify-between space-x-2 items-center">
          <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4" r="4" fill="#38D5FF"/>
          </svg>
          <div class="text-2xl font-bold">{{viewCount}}</div>
          <div class="">page views</div>
        </div>
        <div v-if="percentViews != 0" :class="trendColor(percentViews)">
          <font-awesome-icon class="h-4 w-4 mr-1" :icon="['far', trendIcon(percentViews)]" />
          <span>{{percentDisplay(percentViews)}}</span>
        </div>
      </div>
    </div>
    <div class="w-full h-[130px] pl-4">
      <apexchart height="100%"
                 width="100%"
                 type="area"
                 :series="seriesData"
                 :options="chartOptions"></apexchart>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    visitorCount: String,
    viewCount: String,
    percentVisits: Number,
    percentViews: Number,
    dataPointsViews: Array,
    dataPointsVisits: Array,
  },
  data() {
    return {
    }
  },
  components: {
  },
  computed: {
    seriesData() {
      return [
        { data: this.dataPointsVisits },
        { data: this.dataPointsViews }
      ]
    },
    // https://apexcharts.com/docs/options/
    chartOptions() {
      return {
        stroke: {
          colors: ['#DF59F7', '#38D5FF']
        },
        fill: {
          type: "gradient",
          colors: ['#DF59F7', '#38D5FF'],
          gradient: {
            opacityFrom: 0.4,
            opacityTo: 0,
          }
        },
        tooltip: {
          inverseOrder: true,
          fixed: {
            enabled: false
          },
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function (seriesName) {
                return ''
              }
            }
          },
          marker: {
            show: false
          }
        },
        chart: {
          sparkline: {
            enabled: true
          },
        },
      }
    }
  },
  methods: {
    trendIcon(percent) {
      return (percent > 0) ? 'arrow-trend-up' : 'arrow-trend-down'
    },
    trendColor(percent) {
      return (percent > 0) ? 'text-green-600' : 'text-red-600'
    },
    percentDisplay(percent) {
      return Math.abs(percent) + "%"
    },
  },
}
</script>

