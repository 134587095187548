<template>
  <div class="rounded-md p-4" :class="[ bgColor, inline ? 'py-2 inline-flex' : '' ]">
    <div class="flex-1 flex p-px">
      <div class="flex-shrink-0">
        <font-awesome-icon class="align-top h-5 w-5" :class="textColor" :icon="['fas', icon]" />
      </div>
      <div class="ml-3">
        <p class="font-medium" :class="textColor">
          <slot />
        </p>
        <div v-if="actions && actions.length" class="mt-4">
          <div class="-mx-2 -my-1.5 flex space-x-3">
            <a v-for="(action, index) in actions" :key="index" class="flex items-center px-2 py-1.5 rounded-md font-extrabold hover:bg-black/5 focus:outline-none focus:bg-black/10" :class="[ bgColor, textColor, textColorHover ]" :href="action.href" v-bind="action.attrs">
              {{ action.name }}
              <font-awesome-icon class="ml-1 h-3 w-3" :icon="['fas', 'angle-right']" />
            </a>
          </div>
        </div>
      </div>
      <div v-if="dismissible" class="ml-auto pl-3">
        <div class="-mx-1.5 -my-1.5">
          <button class="inline-flex rounded-md opacity-60 focus:outline-none p-1.5 hover:opacity-100 hover:bg-black/5 focus:bg-black/10" :class="textColor" type="button" @click="$el.remove()">
            <span class="sr-only">Dismiss</span>
            <font-awesome-icon class="h-5 w-5" :icon="['fas', 'xmark']" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const types = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
  INFO: 'info'
}

const settings = {
  error: {
    bgColor: 'bg-error-100',
    textColor: 'text-error-800',
    textColorHover: 'hover:text-error-900',
    icon: 'xmark'
  },
  warning: {
    bgColor: 'bg-warning-100',
    textColor: 'text-warning-700',
    textColorHover: 'hover:text-warning-800',
    icon: 'circle-exclamation'
  },
  success: {
    bgColor: 'bg-success-100',
    textColor: 'text-success-800',
    textColorHover: 'hover:text-success-900',
    icon: 'circle-check'
  },
  info: {
    bgColor: 'bg-brand-blue-100',
    textColor: 'text-brand-blue-800',
    textColorHover: 'hover:text-brand-blue-900',
    icon: 'circle-info'
  }
}

export default {
  props: {
    type: {
      type: String,
      validator: (value) => [types.ERROR, types.WARNING, types.SUCCESS, types.INFO].includes(value)
    },
    dismissible: Boolean,
    actions: Array,
    inline: Boolean
  },
  computed: {
    typeOrDefault() {
      return this.type || types.INFO
    },
    bgColor() {
      return settings[this.typeOrDefault].bgColor
    },
    textColor() {
      return settings[this.typeOrDefault].textColor
    },
    textColorHover() {
      return settings[this.typeOrDefault].textColorHover
    },
    icon() {
      return settings[this.typeOrDefault].icon
    }
  }
}
</script>
