<template>
  <div>
    <alert v-if="showFlash" class="mb-4" type="success" dismissible>Account saved.</alert>
    
    <form class="simple_form form edit_account" id="edit_account">

      <form-heading title="Account Details" description="Basic settings that apply across your site" />

      <div ref="errorSummary">
        <p v-if="hasErrors('base')" style="color: #cc4b37;">{{errors.base[0]}}</p>
        <p v-if="this.errors && !hasErrors('base')" style="color: #cc4b37;">Please fix the errors below</p>
      </div>

      <form-inputs>

        <div class="form-input string required account_name" v-bind:class="{ error: hasErrors('name') }">
          <label class="form-label string required" for="account_name">
            Channel Name
            <abbr title="required">*</abbr>
          </label>
          <div class="form-input-main">
            <input v-model="name" autofocus="autofocus" class="string required" type="text" id="account_name" required />
            <small v-if="hasErrors('name')" small class="form-error">{{errors.name[0]}}</small>
          </div>
        </div>

        <div class="form-input string required account_color" v-bind:class="{ error: hasErrors('color') }">
          <label class="form-label string required" for="account_color">
            Color
            <abbr title="required">*</abbr>
          </label>
          <div class="form-input-main">
            <input v-model="color" placeholder="#cccccc" class="xs string required" type="text" id="account_color" required />
            <small v-if="hasErrors('color')" small class="form-error">{{errors.color[0]}}</small>
          </div>
        </div>          

        <div class="form-input string account_color" v-bind:class="{ error: hasErrors('logo_background_color') }">
          <label class="form-label string required" for="account_logo_background_color">
            Logo Background Color
          </label>
          <div class="form-input-main">
            <input v-model="logoBackgroundColor" placeholder="#cccccc" class="xs string" type="text" id="account_logo_background_color" />
            <small v-if="hasErrors('logo_background_color')" small class="form-error">{{errors.logo_background_color[0]}}</small>
          </div>
        </div>          
        
        <div class="form-input string required image_file" v-bind:class="{ error: !logoValid }">
          <label class="form-label string required">
            Logo
            <abbr title="required">*</abbr>
          </label>
          <div class="form-input-main">
            <div v-if="logoUrl.length > 0" class="mb-3">
              <div class="flex items-center justify-center h-64 max-w-lg bg-black rounded-lg p-6">
                <img class="h-auto w-auto max-h-full" :src="logoUrl">
              </div>
              <alert class="mt-3" inline>Logo shown on black background to show how it will appear on Pennant.</alert>
            </div>
            <dashboard-modal :uppy="uppy" :plugins="['ImageEditor']" :open="uploaderModalOpen" :props="dashboardModalOptions" />
            <btn variant="secondary" :icon="false" tag="button" accesskey="a" @click.native.prevent="openUploader">Choose Image</btn>
            <small v-if="hasErrors('logo_url')" small class="form-error">{{errors.logo_url[0]}}</small>
          </div>
        </div>

        <div class="form-input string payment_descriptor" v-bind:class="{ error: hasErrors('payment_descriptor') }">
          <label class="form-label string required" for="account_payment_descriptor">
            Payment Descriptor 
            <abbr title="required">*</abbr>
          </label>
          <div class="form-input-main">
            <input v-model="paymentDescriptor" placeholder="" class="xs string" type="text" id="account_payment_descriptor" />
            <small>How your name appears on a customer's billing statement</small>
            <small v-if="hasErrors('payment_descriptor')" small class="form-error">{{errors.payment_descriptor[0]}}</small>
          </div>
        </div>

      </form-inputs>

      <form-actions>
        <btn tag="button" type="submit" accesskey="s" @click.native.prevent="saveAccount" :disabled="isSubmitting">Save Changes</btn>
      </form-actions>
      
    </form>
  </div>
</template>

<script>
import { DashboardModal } from '@uppy/vue'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/image-editor/dist/style.css'

import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import ImageEditor from '@uppy/image-editor'

export default {
  props: ['initName', 'initLogoUrl', 'initColor', 'initLogoBackgroundColor', 'initPaymentDescriptor'],
  data() {
    return {
      name: this.initName || '',
      color: this.initColor || '',
      logoBackgroundColor: this.initLogoBackgroundColor || '',
      logoUrl: this.initLogoUrl || '',
      paymentDescriptor: this.initPaymentDescriptor || '',
      errors: null,
      showFlash: false,
      isSubmitting: false,
      logoValid: true,
      uploaderModalOpen: false,
      dashboardModalOptions: {
        onRequestCloseModal: this.closeUploader,
        closeAfterFinish: true,
        showLinkToFileUploadResult: false,
        note: "Image should be 16:9 ratio. Click the pencil icon to crop if necessary.",
        locale: {
          strings: {
            dropPaste: 'Drop image file here, paste or %{browse}',
          }
        }
      }
    }
  },
  components: {
    DashboardModal,
  },
  computed: {
    uppy: function () {
      return new Uppy({
        allowMultipleUploads: false,
        restrictions: {
          allowedFileTypes: ['image/*'],
          minNumberOfFiles: 1,
          maxNumberOfFiles: 1,
          maxTotalFileSize: 10485760
        }
      }).use(ImageEditor, {
        id: 'ImageEditor',
        quality: 0.8,
        actions: {
          revert: false,
          rotate: false,
          flip: false,
          zoomIn: true,
          zoomOut: true,
          cropSquare: false,
          cropWidescreen: false,
          cropWidescreenVertical: false
        },
        cropperOptions: {
          viewMode: 1,
          background: false,
          autoCropArea: 1,
          responsive: true,
          aspectRatio: 16/9,
        },
      }).use(AwsS3Multipart, {
        companionUrl: '/',
      }).on('upload-success', (file, response) => {
        // console.log("file upload success")
        // console.log("file: ", JSON.stringify(file))
        // console.log("response: ", JSON.stringify(response))
        this.logoUrl = response.uploadURL
      })
    },
  },
  beforeDestroy() {
    this.uppy.close()
  },
  mounted() {
  },
  methods: {
    openUploader() {
      this.uploaderModalOpen = true
    },
    closeUploader() {
      this.uploaderModalOpen = false
    },
    saveAccount() {
      this.isSubmitting = true
      
      let params = {
        name: this.name,
        color: this.color,
        logo_background_color: this.logoBackgroundColor,
        logo_url: this.logoUrl,
        payment_descriptor: this.paymentDescriptor,
      }
      
      let requestParams = {
        method: 'put',
        url: '/account',
        data: params,
        headers: {
          'X-CSRF-Token': document.querySelectorAll(`meta[name="csrf-token"]`)[0].getAttribute('content')
        }
      }
      
      this.$http(requestParams)
        .then((response) => {
          if (response.data.success) {
            this.errors = null;
            this.showFlash = true
            window.scrollTo(0,0)
          } else {
            this.showFlash = false
            this.errors = response.data.errors            
            this.$refs.errorSummary.scrollIntoView()
          }
          this.isSubmitting = false
        }).catch((e) => {
          console.log(e)
        })
    },
    hasErrors(field) {
      return (this.errors && this.errors[field]);
    },
  },
}  
</script>
