<template>
  <div :class="tabs && tabs.length ? 'pb-6 sm:pb-0' : 'pb-6'">
    <div v-if="breadcrumbs && breadcrumbs.length" class="mb-2">
      <nav class="sm:hidden" aria-label="Back">
        <a class="flex items-center font-medium text-neutral-500 hover:text-neutral-700" :href="breadcrumbs[breadcrumbs.length - 1][1]">
          <font-awesome-icon class="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-neutral-400" :icon="['fas', 'angle-left']" />
          Back
        </a>
      </nav>
      <nav class="hidden sm:flex" aria-label="Breadcrumb">
        <ol class="flex items-center space-x-2" role="list">
          <li v-for="(breadcrumb, index) in breadcrumbs" :key="index">
            <div class="flex items-center">
              <a class="font-medium text-neutral-500 hover:text-neutral-700" :href="breadcrumb[1]">{{ breadcrumb[0] }}</a>
              <font-awesome-icon class="flex-shrink-0 h-4 w-4 ml-2 text-neutral-400" :icon="['fas', 'angle-right']" />
            </div>
          </li>
        </ol>
      </nav>
    </div>
    <div class="md:flex md:items-center md:justify-between">
      <!-- 'self-start' class doesn't work here, hence the style attr -->
      <div class="flex-1 flex items-center min-w-0 py-px" :style="title == 'Dashboard' ? 'align-self: flex-start;' : ''">
        <div v-if="icon" class="flex items-center justify-center mr-2 h-9 w-9 rounded-full bg-black">
          <font-awesome-icon class="h-5 w-5 text-primary" :icon="['fas', icon]" />
        </div>
        <h2 class="sm:truncate">{{ title }}</h2>
      </div>
      <div v-if="$slots.default" class="mt-4 flex md:mt-0 md:ml-4 space-x-2">
        <slot />
      </div>
    </div>
    <div v-if="tabs && tabs.length" class="mt-4 sm:mt-5">
      <!-- Dropdown menu on small screens -->
      <div class="sm:hidden">
        <label class="sr-only" for="current-tab">Select a tab</label>
        <select id="current-tab" name="current-tab" @change="dropdownChange">
          <option v-for="(tab, index) in tabs" :key="index" :value="tab[1]" :selected="currentPage(tab[1])">{{ tab[0] }}</option>
        </select>
      </div>
      <!-- Tabs at small breakpoint and up -->
      <div class="hidden sm:block">
        <nav class="-mx-1 -mb-px flex space-x-6">
          <a v-for="(tab, index) in tabs" :key="index" class="whitespace-nowrap pb-4 px-1 border-b-3 font-medium" :class="currentPage(tab[1]) ? 'border-brand-blue' : 'border-transparent text-neutral-500 hover:text-neutral-700 hover:border-neutral-300'" :href="tab[1]">
            {{ tab[0] }}
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    icon: String,
    breadcrumbs: Array,
    tabs: Array
  },
  methods: {
    currentPage(path) {
      return window.location.pathname === path
    },
    dropdownChange(e) {
      window.location.href = e.target.value
    }
  }
}
</script>
