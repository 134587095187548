import Vue from 'vue/dist/vue.esm'

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

import VCalendar from 'v-calendar'
Vue.use(VCalendar)

// dashboard-datepicker uses this for its preset popup
import VueClosable from 'vue-closable'
Vue.use(VueClosable)

import AccountForm from './components/account_form.vue'
import AccountPlanSelect from './components/account_plan_select.vue'
import Alert from './components/alert.vue'
import Application from './components/application.vue'
import Btn from './components/btn.vue'
import CategoryForm from './components/category_form.vue'
import Dashboard from './components/dashboard.vue'
import DashboardHeaderItem from './components/dashboard_header_item.vue'
import Empty from './components/empty.vue'
import FormActions from './components/form_actions.vue'
import FormFooter from './components/form_footer.vue'
import FormHeading from './components/form_heading.vue'
import FormInputs from './components/form_inputs.vue'
import HomepageForm from './components/homepage_edit_form.vue'
import Item from './components/item.vue'
import ItemEmpty from './components/item_empty.vue'
import List from './components/list.vue'
import ListItem from './components/list_item.vue'
import LiveStreamForm from './components/live_stream_form.vue'
import PlanOption from './components/plan_option.vue'
import SeriesForm from './components/series_form.vue'
import SeriesVideos from './components/series_videos.vue'
import SlideOver from './components/slide_over.vue'
import SubscriptionForm from './components/subscription_form.vue'
import Tabl from './components/tabl.vue'
import VideoForm from './components/video_form.vue'
import VideoEditForm from './components/video_edit_form.vue'

Vue.component('apexchart', VueApexCharts)

Vue.component('account-form', AccountForm)
Vue.component('account-plan-select', AccountPlanSelect)
Vue.component('alert', Alert)
Vue.component('application', Application)
Vue.component('btn', Btn)
Vue.component('category-form', CategoryForm)
Vue.component('dashboard', Dashboard)
Vue.component('dashboard-header-item', DashboardHeaderItem)
Vue.component('empty', Empty)
Vue.component('form-actions', FormActions)
Vue.component('form-footer', FormFooter)
Vue.component('form-heading', FormHeading)
Vue.component('form-inputs', FormInputs)
Vue.component('homepage-edit-form', HomepageForm)
Vue.component('item', Item)
Vue.component('item-empty', ItemEmpty)
Vue.component('list', List)
Vue.component('list-item', ListItem)
Vue.component('live-stream-form', LiveStreamForm)
Vue.component('plan-option', PlanOption)
Vue.component('series-form', SeriesForm)
Vue.component('series-videos', SeriesVideos)
Vue.component('slide-over', SlideOver)
Vue.component('subscription-form', SubscriptionForm)
Vue.component('tabl', Tabl)
Vue.component('video-form', VideoForm)
Vue.component('video-edit-form', VideoEditForm)
