<template>
  <v-date-picker v-model="range"
                 :disabled-dates="disabledDates"
                 :model-config="modelConfig"
                 :popover="popover"
                 :masks="masks"
                 is-range
                 color="gray"
                 is-dark
                 class="-mt-10 mb-8 flex items-center rounded-full bg-white w-fit uppercase text-xs tracking-wider"
                 @input="onDateSelected"
                 :key="datePickerKey"
                 >
    <template v-slot="{ inputValue, inputEvents, togglePopover }">
      <div v-show="showPresetsPopup"
           class="relative"
           v-closable="{
                       handler: 'handleOutsideClick',
                       exclude: ['presetsPopup','presetsButton']
                       }"
           >
        <div class="dashboard-datepicker-presets-popup" ref="presetsPopup">
          <div class="dashboard-datepicker-days dashboard-datepicker-preset" @click="selectPresetRange(7)">7 days</div>
          <div class="dashboard-datepicker-days dashboard-datepicker-preset" @click="selectPresetRange(30)">30 days</div>
          <div class="dashboard-datepicker-days dashboard-datepicker-preset" @click="selectPresetRange(90)">90 days</div>
          <div class="dashboard-datepicker-days dashboard-datepicker-preset" @click="selectPresetRange(365)">1 year</div>
          <div class="dashboard-datepicker-days dashboard-datepicker-preset" @click="selectPresetLifetime()">Lifetime</div>
          <div class="dashboard-datepicker-days dashboard-datepicker-preset" @click="togglePresetsPopup();togglePopover()">Custom</div>
        </div>
      </div>  
      <div class="dashboard-datepicker-days" ref="presetsButton" @click="togglePresetsPopup()">
        <div>{{daysFormatted}}</div>
        <font-awesome-icon class="self-start h-3 w-3 ml-1.5 mt-[1px]" :icon="['far', 'chevron-down']" />
      </div>
      <div class="py-2 px-4 font-normal flex cursor-default">
        <div v-on="inputEvents.start">{{inputValue.start}}</div>
        <div class="px-1">–</div>
        <div v-on="inputEvents.end">{{inputValue.end}}</div>
      </div>
    </template>
  </v-date-picker>
</template>

<script>

export default {
  props: {
    dateStart: String,
    dateEnd: String,
    dateTenantCreated: String,
  },
  data() {
    return {
      datePickerKey: 0,
      days: null,
      initialDateEnd: this.dateEnd,
      range: {
        start: this.dateStart,
        end: this.dateEnd
      },
      disabledDates: {
        start: new Date(),
        end: new Date(2099, 1, 1),
      },
      masks: {
        input: 'MMM DD, YYYY'
      },
      modelConfig: {
        // auto-converts from Date
        // This lets us pass in string props to the component
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      popover: {
        // to hide on hover over selected date text
        visibility: 'hidden'
      },
      showPresetsPopup: false,
    }
  },
  mounted() {
    this.setDays()
  },
  components: {
  },
  computed: {
    startDate() {
      return new Date(this.range.start)
    },
    endDate() {
      return new Date(this.range.end)
    },
    daysFormatted() {
      if (this.days == 1) {
        return "1 day"
      } else if (this.days == 365) {
        return "1 year"
      } else if (this.range.start == this.dateTenantCreated) {
        return "lifetime"
      }

      return this.days + " days"
    },    
  },
  methods: {
    onDateSelected(date) {
      this.$emit('date-changed', this.range)
      
      this.setDays()
    },
    setDays() {
      let secDiff = this.endDate.getTime() - this.startDate.getTime()
      this.days = Math.round(secDiff / (1000 * 3600 * 24)) + 1
    },    
    togglePresetsPopup() {
      this.showPresetsPopup = !this.showPresetsPopup
    },
    handleOutsideClick() {
      this.showPresetsPopup = false
    },
    selectPresetLifetime() {
      const ms = new Date(this.initialDateEnd) - new Date(this.dateTenantCreated)
      const numDays = Math.round(ms / (1000 * 60 * 60 * 24)) + 1
      this.selectPresetRange(numDays)
    },
    selectPresetRange(numDays) {
      this.showPresetsPopup = false
      
      const _endDate = new Date(this.initialDateEnd) // yesterday
      const dateOffset = (24 * 60 * 60 * 1000) * (numDays - 1)
      const _startDate = new Date(_endDate.getTime() - dateOffset)
      
      this.range.start = this.toIsoDate(_startDate)
      this.range.end = this.toIsoDate(_endDate)
      this.onDateSelected(null)
      this.datePickerKey++
    },
    toIsoDate(date) {
      return date.toISOString().split('T')[0]
    }
  },
}
</script>
