// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

// start application.js

// require("@rails/ujs").start()
import Rails from '@rails/ujs'
if (import.meta.env.DEV) {
  // for some reason this is started in prod mode but not in dev
  Rails.start()
}
// require("turbolinks").start()
// require("@rails/activestorage").start()

import Vue from 'vue/dist/vue.esm'
import axios from 'axios'
import './../components'
import './../icons'

// Heroku: for some reason routes.js is not getting generated before Webpack builds.
// Workaround is to check in routes.js, which is normally in .gitignore
// In needed, it can be regenerated with this task:
//
// bundle exec rake js:routes:typescript
import * as Routes from './../routes'
window.Routes = Routes

import { videoSetup } from './../modules/video'

Vue.prototype.$http = axios

window.Lyra = {}

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '[data-behavior="vue"]',
    data: {
      Routes
    }
  })
})

document.addEventListener('DOMContentLoaded', videoSetup )
