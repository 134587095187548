<template>
  <div class="flex items-center justify-center min-h-64 p-4 text-center border-3 border-dashed border-neutral-200 rounded-lg">
    <div>
      <font-awesome-icon v-if="icon" class="block mx-auto mb-3 h-16 w-16 text-neutral-400" :icon="['fas', icon]" />
      <h3 v-if="title">{{ title }}</h3>
      <p v-if="description || $slots.default" class="mt-1 text-neutral-500">
        <slot>{{ description }}</slot>
      </p>
      <btn v-if="buttonText && buttonHref && !hideButtonIcon" class="mt-6" :href="buttonHref" icon="plus" icon-placement="before">
        {{ buttonText }}
      </btn>
      <btn v-if="buttonText && buttonHref && hideButtonIcon" class="mt-6" :href="buttonHref">
        {{ buttonText }}
      </btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    title: String,
    description: String,
    buttonText: String,
    buttonHref: String,
    hideButtonIcon: {
      default: false,
      type: Boolean
    }
  }
}
</script>
